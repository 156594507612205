@charset "UTF-8";
/*
* Theme Name: GeoTrip - Tour & Travel Agency HTML Template
* Author: Themez Hub
* Version: 1.0.0
* Last Change: 26 Oct 2023
  Author URI    : http://www.Themezhub.com/

This files table contents are outlined below>>>>>

*******************************************
*******************************************

** - Common Elentns 
** - Default Style
** - Navbar & Header Styles
** - Intro Banner & Hero Header
** - GeoTrip Elements Styles
** - Footer Style
** - Responsiveness Start
*/
/*--------------------------------------------------
Default Style
---------------------------------------------------*/
@import url("https://fonts.googleapis.com/css2?family=Jost:wght@200;300;400;500;600;700;800&amp;display=swap");
:root,
[data-bs-theme=light] {
  --bs-primary: #cd2c22;
  --bs-primary-bg-subtle: #ffe6e5;
  --bs-primary-bg-dark: #c4281e;
  --bs-secondary: #c3d2de;
  --bs-secondary-bg-subtle: #dae5ee;
  --bs-success: #27a974;
  --bs-success-bg-subtle: #d4eee3;
  --bs-success-bg-dark: #1ea36d;
  --bs-info: #106BDA;
  --bs-info-bg-subtle: #cfe1f8;
  --bs-info-bg-dark: #0b63d0;
  --bs-warning: #ffc107;
  --bs-warning-bg-subtle: #fff3cd;
  --bs-warning-bg-dark: #f0b503;
  --bs-danger: #CE3426;
  --bs-danger-bg-subtle: #ffe2e0;
  --bs-danger-bg-dark: #cb3022;
  --bs-light: #f4f5f7;
  --bs-dark: #0e1e2b;
  --bs-cyan: #04765f;
  --bs-cyan-bg-subtle: #cdf1e9;
  --bs-cyan-bg-dark: #036f59;
  --bs-purple: #6363f5;
  --bs-purple-bg-subtle: #dedeff;
  --bs-purple-bg-dark: #5b5bed;
  --bs-primary-rgb: 205, 44, 34;
  --bs-secondary-rgb: 195, 210, 222;
  --bs-success-rgb: 39, 169, 116;
  --bs-info-rgb: 16, 107, 218;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 206, 52, 38;
  --bs-cyan-rgb: 4, 118, 95;
  --bs-purple-rgb: 99, 99, 245;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 14, 30, 43;
  --body-font-family: "Jost", sans-serif;
  --heading-font-family: "Jost", sans-serif;
  --bs-body-color: #2d3033;
  --paragraphColor: #5d6f7d;
  --headingColor: #05264e;
  --fontSize: 15px;
}

/* ----------------- Default Style: Typography Styles ----------------------- */
.dark #preloader {
  background-color: #232323;
}

/*-------------- Not Use -----------
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f7f7f7;
  z-index: 999999;
}

.preloader {
  width: 50px;
  height: 50px;
  display: inline-block;
  padding: 0px;
  text-align: left;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -25px;
  margin-top: -25px;
}

.preloader span {
  position: absolute;
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background: var(--bs-primary);
  -webkit-animation: preloader 1.3s linear infinite;
  animation: preloader 1.3s linear infinite;
}

.preloader span:last-child {
  animation-delay: -0.8s;
  -webkit-animation-delay: -0.8s;
}
---------------- */
body {
  background: #ffffff;
  color: var(--bs-body-color);
  font-size: 15px;
  font-family: var(--body-font-family);
  margin: 0;
  overflow-x: hidden !important;
  font-weight: 400;
}

.text-body-secondary {
  --bs-text-opacity: 1;
  color: #5d6f7d !important;
}

.opacity-1 {
  opacity: 0.1;
}

.p,
p {
  line-height: 1.8;
  color: var(--paragraphColor);
}

.form-group {
  margin-bottom: 15px;
}

.font--bold {
  font-weight: 600;
}

.modal-open {
  overflow: hidden !important;
  padding-right: 0 !important;
}

.br-dashed {
  border-style: dashed !important;
}

a {
  text-decoration: none;
  color: #022f5d;
}

a:hover,
a:focus {
  text-decoration: none;
}

.nav-link {
  color: #455873;
}

.nav-link:hover,
.nav-link:focus {
  color: var(--bs-primary);
}

.page-link {
  color: var(--bs-dark);
}

.page-link:hover,
.page-link:focus {
  color: var(--bs-primary);
}

html body .mdi:before,
html body .mdi-set {
  line-height: initial;
}

section {
  padding: 80px 0 80px;
}

section.min {
  padding: 50px 0 50px;
}

section.mid {
  padding: 80px 0 50px;
}

.gray {
  background: #f7f9fc !important;
}

.gray-simple {
  background: #f1f5f8 !important;
}

.bg-title {
  background: #2540a2;
}

.tbl {
  display: table;
}

.full-height {
  height: 100%;
}

.alert p {
  margin: 0;
}

.table-cell {
  display: table-cell;
  vertical-align: middle;
}

.no-ul-list {
  padding: 0;
}

.no-ul-list li {
  list-style: none;
}

.text-muted-2 {
  color: #455873 !important;
}

.text-muted {
  color: var(--bs-gray-500) !important;
}

.text-seegreen,
.text-seagreen {
  color: var(--bs-cyan);
}

.text-purple {
  color: var(--bs-purple);
}

.text-sm-muted {
  font-size: 12px;
  font-weight: 500;
  color: var(gray-800);
}

.text-xs {
  font-size: 10px;
}

.text-sm {
  font-size: 12px;
}

.text-md {
  font-size: 14px;
}

.type--fade {
  opacity: 0.5;
}

.type--fine-print {
  font-size: 0.85714286em;
}

blockquote {
  font-size: 1.78571429em;
  line-height: 1.5em;
  padding: 0;
  margin: 0;
  border-left: 0;
}

blockquote {
  font-style: italic;
}

blockquote:not(:last-child) {
  margin-bottom: 1.04em;
}

.sec-heading {
  margin-bottom: 2rem;
}
.sec-heading .lg {
  margin-bottom: 30px;
}
.sec-heading .center {
  text-align: center;
}
.sec-heading .light h2 {
  color: #ffffff;
}
.sec-heading .light p {
  color: #ffffff;
}
.sec-heading p {
  margin-bottom: 5px;
  line-height: 1.7;
}

.sec-heading2 {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--headingColor);
  font-weight: 700;
  font-family: var(heading-font-family);
}

/* ----------------- Default Style: Animation ----------------------- */
@keyframes preloader {
  0% {
    transform: scale(0, 0);
    opacity: 0.5;
  }
  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}
@keyframes anim-francisco {
  50% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  51% {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes bounce {
  0%, 100% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: translateY(0);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}
@keyframes leftright {
  0%, 100% {
    transform: translatex(-25%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: translatex(0);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}
@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
  }
}
.animate-bounce {
  animation: bounce 2s infinite;
}

.animate-leftright {
  animation: leftright 2s infinite;
}

/* ----------------- Default Style: Buttons Styles ----------------------- */
.btn {
  padding: 10px 20px;
  height: 56px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all ease 0.4s;
  border-radius: 0.375rem;
  overflow: hidden;
  animation-delay: 0.7s;
  animation-duration: 0.5s;
  position: relative;
}
.btn::after {
  background-color: rgba(255, 255, 255, 0.76);
  content: "";
  height: 150px;
  left: -75px;
  position: absolute;
  top: -35px;
  transform: rotate(35deg);
  transition: all 1600ms cubic-bezier(0.19, 1, 0.22, 1);
  width: 40px;
  opacity: 0;
}
.btn:hover:after {
  left: 120%;
  transition: all 1300ms cubic-bezier(0.19, 1, 0.22, 1);
  opacity: 0.25;
}

.btn:focus,
.btn:hover {
  box-shadow: none;
}

.btn-lg {
  padding: 1em 1.5em;
  height: 62px;
  font-size: 1em;
}

.btn-md {
  padding: 1em 1.5em;
  height: 45px;
  font-size: 0.9rem;
}

.btn-sm {
  padding: 0.25rem 0.5rem;
  height: 35px;
  font-size: 12px;
}

.btn-rounded .btn-xxl {
  width: 80px;
  height: 80px;
}

.btn-rounded .btn-xl {
  width: 70px;
  height: 70px;
}

.btn-rounded .btn-lg {
  width: 60px;
  height: 60px;
}

.btn-rounded .btn-md {
  width: 44px;
  height: 44px;
}

.btn-rounded .btn-sm {
  width: 35px;
  height: 35px;
}

.btn-rounded .btn-xs {
  width: 28px;
  height: 28px;
}

.btn-filter {
  padding: 0.25rem 0.8rem;
  height: 40px;
  font-size: 14px;
}

.btn-xs {
  padding: 0.2rem 0.5rem;
  height: 30px;
  font-size: 10px;
}

.btn-rounded {
  border-radius: 50px !important;
  -webkit-border-radius: 50px !important;
}

.btn-arrow {
  position: relative;
}

.btn-success {
  --bs-btn-color: #ffffff;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-active-color: #ffffff;
  --bs-btn-disabled-color: #ffffff;
}

.btn-primary {
  background: var(--bs-primary);
  border-color: var(--bs-primary);
  color: var(--bs-white);
}
.btn-primary:hover {
  background: var(--bs-primary-bg-dark) !important;
  border-color: var(--bs-primary-bg-dark) !important;
  color: var(--bs-white) !important;
}
.btn-primary:focus {
  background: var(--bs-primary-bg-dark) !important;
  border-color: var(--bs-primary-bg-dark) !important;
  color: var(--bs-white) !important;
}
.btn-primary:active {
  background: var(--bs-primary-bg-dark) !important;
  border-color: var(--bs-primary-bg-dark) !important;
  color: var(--bs-white) !important;
}

.btn-light-primary {
  background: var(--bs-primary-bg-subtle);
  border-color: var(--bs-primary-bg-subtle);
  color: var(--bs-primary);
}
.btn-light-primary:hover {
  background: var(--bs-primary) !important;
  border-color: var(--bs-primary) !important;
  color: var(--bs-white) !important;
}
.btn-light-primary:focus {
  background: var(--bs-primary) !important;
  border-color: var(--bs-primary) !important;
  color: var(--bs-white) !important;
}
.btn-light-primary:active {
  background: var(--bs-primary) !important;
  border-color: var(--bs-primary) !important;
  color: var(--bs-white) !important;
}

.btn-outline-primary {
  background: var(--bs-white);
  border-color: var(--bs-primary);
  color: var(--bs-primary);
}
.btn-outline-primary:hover {
  background: var(--bs-primary) !important;
  border-color: var(--bs-primary) !important;
  color: var(--bs-white) !important;
}
.btn-outline-primary:focus {
  background: var(--bs-primary) !important;
  border-color: var(--bs-primary) !important;
  color: var(--bs-white) !important;
}
.btn-outline-primary:active {
  background: var(--bs-primary) !important;
  border-color: var(--bs-primary) !important;
  color: var(--bs-white) !important;
}

.btn-success {
  background: var(--bs-success);
  border-color: var(--bs-success);
  color: var(--bs-white);
}
.btn-success:hover {
  background: var(--bs-success-bg-dark) !important;
  border-color: var(--bs-success-bg-dark) !important;
  color: var(--bs-white) !important;
}
.btn-success:focus {
  background: var(--bs-success-bg-dark) !important;
  border-color: var(--bs-success-bg-dark) !important;
  color: var(--bs-white) !important;
}
.btn-success:active {
  background: var(--bs-success-bg-dark) !important;
  border-color: var(--bs-success-bg-dark) !important;
  color: var(--bs-white) !important;
}

.btn-light-success {
  background: var(--bs-success-bg-subtle);
  border-color: var(--bs-success-bg-subtle);
  color: var(--bs-success);
}
.btn-light-success:hover {
  background: var(--bs-success) !important;
  border-color: var(--bs-success) !important;
  color: var(--bs-white) !important;
}
.btn-light-success:focus {
  background: var(--bs-success) !important;
  border-color: var(--bs-success) !important;
  color: var(--bs-white) !important;
}
.btn-light-success:active {
  background: var(--bs-success) !important;
  border-color: var(--bs-success) !important;
  color: var(--bs-white) !important;
}

.btn-outline-success {
  background: var(--bs-white);
  border-color: var(--bs-success);
  color: var(--bs-success);
}
.btn-outline-success:hover {
  background: var(--bs-success) !important;
  border-color: var(--bs-success) !important;
  color: var(--bs-white) !important;
}
.btn-outline-success:focus {
  background: var(--bs-success) !important;
  border-color: var(--bs-success) !important;
  color: var(--bs-white) !important;
}
.btn-outline-success:active {
  background: var(--bs-success) !important;
  border-color: var(--bs-success) !important;
  color: var(--bs-white) !important;
}

.btn-danger {
  background: var(--bs-danger);
  border-color: var(--bs-danger);
  color: var(--bs-white);
}
.btn-danger:hover {
  background: var(--bs-danger-bg-bg-dark) !important;
  border-color: var(--bs-danger-bg-dark) !important;
  color: var(--bs-white) !important;
}
.btn-danger:focus {
  background: var(--bs-danger-bg-dark) !important;
  border-color: var(--bs-danger-bg-dark) !important;
  color: var(--bs-white) !important;
}
.btn-danger:active {
  background: var(--bs-danger-bg-dark) !important;
  border-color: var(--bs-danger-bg-dark) !important;
  color: var(--bs-white) !important;
}

.btn-light-danger {
  background: var(--bs-danger-bg-subtle);
  border-color: var(--bs-danger-bg-subtle);
  color: var(--bs-danger);
}
.btn-light-danger:hover {
  background: var(--bs-danger) !important;
  border-color: var(--bs-danger) !important;
  color: var(--bs-white) !important;
}
.btn-light-danger:focus {
  background: var(--bs-danger) !important;
  border-color: var(--bs-danger) !important;
  color: var(--bs-white) !important;
}
.btn-light-danger:active {
  background: var(--bs-danger) !important;
  border-color: var(--bs-danger) !important;
  color: var(--bs-white) !important;
}

.btn-outline-danger {
  background: var(--bs-white);
  border-color: var(--bs-danger);
  color: var(--bs-danger);
}
.btn-outline-danger:hover {
  background: var(--bs-danger) !important;
  border-color: var(--bs-danger) !important;
  color: var(--bs-white) !important;
}
.btn-outline-danger:focus {
  background: var(--bs-danger) !important;
  border-color: var(--bs-danger) !important;
  color: var(--bs-white) !important;
}
.btn-outline-danger:active {
  background: var(--bs-danger) !important;
  border-color: var(--bs-danger) !important;
  color: var(--bs-white) !important;
}

.btn-warning {
  background: var(--bs-warning);
  border-color: var(--bs-warning);
  color: var(--bs-white);
}
.btn-warning:hover {
  background: var(--bs-warning-bg-dark) !important;
  border-color: var(--bs-warning-bg-dark) !important;
  color: var(--bs-white) !important;
}
.btn-warning:focus {
  background: var(--bs-warning-bg-dark) !important;
  border-color: var(--bs-warning-bg-dark) !important;
  color: var(--bs-white) !important;
}
.btn-warning:active {
  background: var(--bs-warning-bg-dark) !important;
  border-color: var(--bs-warning-bg-dark) !important;
  color: var(--bs-white) !important;
}

.btn-light-warning {
  background: var(--bs-warning-bg-subtle);
  border-color: var(--bs-warning-bg-subtle);
  color: var(--bs-warning);
}
.btn-light-warning:hover {
  background: var(--bs-warning) !important;
  border-color: var(--bs-warning) !important;
  color: var(--bs-white) !important;
}
.btn-light-warning:focus {
  background: var(--bs-warning) !important;
  border-color: var(--bs-warning) !important;
  color: var(--bs-white) !important;
}
.btn-light-warning:active {
  background: var(--bs-warning) !important;
  border-color: var(--bs-warning) !important;
  color: var(--bs-white) !important;
}

.btn-outline-warning {
  background: var(--bs-white);
  border-color: var(--bs-warning);
  color: var(--bs-warning);
}
.btn-outline-warning:hover {
  background: var(--bs-warning) !important;
  border-color: var(--bs-warning) !important;
  color: var(--bs-white) !important;
}
.btn-outline-warning:focus {
  background: var(--bs-warning) !important;
  border-color: var(--bs-warning) !important;
  color: var(--bs-white) !important;
}
.btn-outline-warning:active {
  background: var(--bs-warning) !important;
  border-color: var(--bs-warning) !important;
  color: var(--bs-white) !important;
}

.btn-info {
  background: var(--bs-info);
  border-color: var(--bs-info);
  color: var(--bs-white);
}
.btn-info:hover {
  background: var(--bs-info-bg-dark) !important;
  border-color: var(--bs-info-bg-dark) !important;
  color: var(--bs-white) !important;
}
.btn-info:focus {
  background: var(--bs-info-bg-dark) !important;
  border-color: var(--bs-info-bg-dark) !important;
  color: var(--bs-white) !important;
}
.btn-info:active {
  background: var(--bs-info-bg-dark) !important;
  border-color: var(--bs-info-bg-dark) !important;
  color: var(--bs-white) !important;
}

.btn-light-info {
  background: var(--bs-info-bg-subtle);
  border-color: var(--bs-info-bg-subtle);
  color: var(--bs-info);
}
.btn-light-info:hover {
  background: var(--bs-info) !important;
  border-color: var(--bs-info) !important;
  color: var(--bs-white) !important;
}
.btn-light-info:focus {
  background: var(--bs-info) !important;
  border-color: var(--bs-info) !important;
  color: var(--bs-white) !important;
}
.btn-light-info:active {
  background: var(--bs-info) !important;
  border-color: var(--bs-info) !important;
  color: var(--bs-white) !important;
}

.btn-outline-info {
  background: var(--bs-white);
  border-color: var(--bs-info);
  color: var(--bs-info);
}
.btn-outline-info:hover {
  background: var(--bs-info) !important;
  border-color: var(--bs-info) !important;
  color: var(--bs-white) !important;
}
.btn-outline-info:focus {
  background: var(--bs-info) !important;
  border-color: var(--bs-info) !important;
  color: var(--bs-white) !important;
}
.btn-outline-info:active {
  background: var(--bs-info) !important;
  border-color: var(--bs-info) !important;
  color: var(--bs-white) !important;
}

.btn-seegreen {
  background: var(--bs-seegreen);
  border-color: var(--bs-seegreen);
  color: var(--bs-white);
}
.btn-seegreen:hover {
  background: var(--bs-seegreen-bg-dark) !important;
  border-color: var(--bs-seegreen-bg-dark) !important;
  color: var(--bs-white) !important;
}
.btn-seegreen:focus {
  background: var(--bs-seegreen-bg-dark) !important;
  border-color: var(--bs-seegreen-bg-dark) !important;
  color: var(--bs-white) !important;
}
.btn-seegreen:active {
  background: var(--bs-seegreen-bg-dark) !important;
  border-color: var(--bs-seegreen-bg-dark) !important;
  color: var(--bs-white) !important;
}

.btn-light-seegreen {
  background: var(--bs-seegreen-bg-subtle);
  border-color: var(--bs-seegreen-bg-subtle);
  color: var(--bs-seegreen);
}
.btn-light-seegreen:hover {
  background: var(--bs-seegreen) !important;
  border-color: var(--bs-seegreen) !important;
  color: var(--bs-white) !important;
}
.btn-light-seegreen:focus {
  background: var(--bs-seegreen) !important;
  border-color: var(--bs-seegreen) !important;
  color: var(--bs-white) !important;
}
.btn-light-seegreen:active {
  background: var(--bs-seegreen) !important;
  border-color: var(--bs-seegreen) !important;
  color: var(--bs-white) !important;
}

.btn-outline-seegreen {
  background: var(--bs-white);
  border-color: var(--bs-seegreen);
  color: var(--bs-seegreen);
}
.btn-outline-seegreen:hover {
  background: var(--bs-seegreen) !important;
  border-color: var(--bs-seegreen) !important;
  color: var(--bs-white) !important;
}
.btn-outline-seegreen:focus {
  background: var(--bs-seegreen) !important;
  border-color: var(--bs-seegreen) !important;
  color: var(--bs-white) !important;
}
.btn-outline-seegreen:active {
  background: var(--bs-seegreen) !important;
  border-color: var(--bs-seegreen) !important;
  color: var(--bs-white) !important;
}

.btn-purple {
  background: var(--bs-purple);
  border-color: var(--bs-purple);
  color: var(--bs-white);
}
.btn-purple:hover {
  background: var(--bs-purple-bg-dark) !important;
  border-color: var(--bs-purple-bg-dark) !important;
  color: var(--bs-white) !important;
}
.btn-purple:focus {
  background: var(--bs-purple-bg-dark) !important;
  border-color: var(--bs-purple-bg-dark) !important;
  color: var(--bs-white) !important;
}
.btn-purple:active {
  background: var(--bs-purple-bg-dark) !important;
  border-color: var(--bs-purple-bg-dark) !important;
  color: var(--bs-white) !important;
}

.btn-light-purple {
  background: var(--bs-purple-bg-subtle);
  border-color: var(--bs-purple-bg-subtle);
  color: var(--bs-purple);
}
.btn-light-purple:hover {
  background: var(--bs-purple) !important;
  border-color: var(--bs-purple) !important;
  color: var(--bs-white) !important;
}
.btn-light-purple:focus {
  background: var(--bs-purple) !important;
  border-color: var(--bs-purple) !important;
  color: var(--bs-white) !important;
}
.btn-light-purple:active {
  background: var(--bs-purple) !important;
  border-color: var(--bs-purple) !important;
  color: var(--bs-white) !important;
}

.btn-outline-purple {
  background: var(--bs-white);
  border-color: var(--bs-purple);
  color: var(--bs-purple);
}
.btn-outline-purple:hover {
  background: var(--bs-purple) !important;
  border-color: var(--bs-purple) !important;
  color: var(--bs-white) !important;
}
.btn-outline-purple:focus {
  background: var(--bs-purple) !important;
  border-color: var(--bs-purple) !important;
  color: var(--bs-white) !important;
}
.btn-outline-purple:active {
  background: var(--bs-purple) !important;
  border-color: var(--bs-purple) !important;
  color: var(--bs-white) !important;
}

.btn-whites {
  background: var(--bs-white);
  border-color: var(--bs-white);
  color: var(--bs-dark);
}
.btn-whites:hover {
  background: var(--bs-dark) !important;
  border-color: var(--bs-dark) !important;
  color: var(--bs-white) !important;
}
.btn-whites:focus {
  background: var(--bs-dark) !important;
  border-color: var(--bs-dark) !important;
  color: var(--bs-white) !important;
}
.btn-whites:active {
  background: var(--bs-dark) !important;
  border-color: var(--bs-dark) !important;
  color: var(--bs-white) !important;
}

.btn-outline-whites {
  background: transparent;
  border-color: var(--bs-white);
  color: var(--bs-white);
}
.btn-outline-whites:hover {
  background: var(--bs-white) !important;
  border-color: var(--bs-white) !important;
  color: var(--bs-dark) !important;
}
.btn-outline-whites:focus {
  background: var(--bs-white) !important;
  border-color: var(--bs-white) !important;
  color: var(--bs-dark) !important;
}
.btn-outline-whites:active {
  background: var(--bs-white) !important;
  border-color: var(--bs-white) !important;
  color: var(--bs-dark) !important;
}

.btn-light-white {
  background: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.65);
  color: var(--bs-white);
}
.btn-light-white:hover {
  background: var(--bs-white) !important;
  border-color: var(--bs-white) !important;
  color: var(--bs-dark) !important;
}
.btn-light-white:focus {
  background: var(--bs-white) !important;
  border-color: var(--bs-white) !important;
  color: var(--bs-dark) !important;
}
.btn-light-white:active {
  background: var(--bs-white) !important;
  border-color: var(--bs-white) !important;
  color: var(--bs-dark) !important;
}

.btn-whitener {
  background: var(--bs-white);
  border-color: var(--bs-white);
  color: var(--bs-dark);
}
.btn-whitener:hover {
  background: var(--bs-primary) !important;
  border-color: var(--bs-primary) !important;
  color: var(--bs-white) !important;
}
.btn-whitener:focus {
  background: var(--bs-primary) !important;
  border-color: var(--bs-primary) !important;
  color: var(--bs-white) !important;
}
.btn-whitener:active {
  background: var(--bs-primary) !important;
  border-color: var(--bs-primary) !important;
  color: var(--bs-white) !important;
}

.btn-gray {
  background: #e4eaed;
  border-color: #e4eaed;
  color: var(--bs-dark);
}
.btn-gray:hover {
  background: var(--bs-dark) !important;
  border-color: var(--bs-dark) !important;
  color: var(--bs-white) !important;
}
.btn-gray:focus {
  background: var(--bs-dark) !important;
  border-color: var(--bs-dark) !important;
  color: var(--bs-white) !important;
}
.btn-gray:active {
  background: var(--bs-dark) !important;
  border-color: var(--bs-dark) !important;
  color: var(--bs-white) !important;
}

.btn.btn-dark-soft {
  color: var(--bs-white);
  background: rgba(245, 246, 247, 0.1);
}
.btn.btn-dark-soft:hover {
  color: var(--bs-dark);
  background: var(--bs-white);
}
.btn.btn-dark-soft:focus {
  color: var(--bs-dark);
  background: var(--bs-white);
}
.btn.btn-dark-soft:active {
  color: var(--bs-dark);
  background: var(--bs-white);
}

/* ----------------- Default Style: Custom Forms Styles ----------------------- */
.form-control {
  height: 56px;
  font-size: 14px;
  box-shadow: none;
  border: 1px solid #dadfe6;
  background-clip: initial;
}
.form-control .form-control-md {
  height: auto;
}
.form-control .light {
  background: rgba(255, 255, 255, 0.01);
  border-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.7);
}
.form-control .dark {
  background: rgba(0, 0, 0, 0.1);
  border-color: rgba(0, 0, 0, 0.1);
  color: rgba(255, 255, 255, 0.8);
}

.form-group label {
  margin-bottom: 4px;
  font-size: 12px;
  font-weight: 500;
}

textarea.form-control {
  height: 150px !important;
}

.select2-container--default .select2-results__option--selected {
  background-color: var(--bs-primary-bg-subtle) !important;
  color: var(--bs-primary) !important;
}

.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: var(--bs-primary) !important;
  color: var(--bs-white) !important;
}

.select2-container .select2-selection--multiple,
.select2-container .select2-selection--single {
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 12px;
  border: 1px solid #dadfe6;
}

.search-wrap .select2-container .select2-selection--multiple,
.search-wrap .select2-container .select2-selection--single {
  height: 62px;
  padding-left: 16px;
}

.search-wrap .form-control {
  height: 62px;
}

.search-wrap .btn {
  height: 62px;
}

.select2-container {
  width: 100% !important;
}

.select2-container .select2-selection--multiple,
.select2-container .select2-selection--single {
  height: 56px;
  padding-left: 16px;
}

.border .select2-container .select2-selection--multiple,
.border .select2-container .select2-selection--single {
  border: none;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  padding-left: 0;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: auto;
  top: 50%;
}

.select2-container--default .select2-selection--multiple {
  padding-bottom: 0;
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
  margin: 0;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  border: 1px solid var(--bs-primary);
  background: var(--bs-primary-bg-subtle);
  color: var(--bs-primary);
  margin: 3px 0px 3px 5px;
  font-size: 13px;
  font-weight: 500;
  height: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
}

.select2-container .select2-search--inline .select2-search__field {
  padding: 0;
  font-weight: 500;
  font-family: inherit;
  margin: 0 !important;
  height: 22px !important;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: var(--bs-primary);
  height: 100%;
  border-right: 1px solid var(--bs-primary-bg-subtle);
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover,
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:focus {
  background-color: var(--bs-primary);
  color: #ffffff;
  outline: none;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: solid #dadfe6 1px;
  outline: 0;
}

.border .select2-container--default.select2-container--focus .select2-selection--multiple {
  border: none;
  outline: 0;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #c0cad0;
  font-weight: 600;
}

.search-wrap .select2-container--default .select2-selection--single .select2-selection__rendered {
  color: var(--bs-body-color);
  font-weight: 700;
  line-height: 28px;
} 
.bt_fp_to input {padding-left: 25px !important;}
.btn-flip-icon {
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 40px;
  background: #ffffff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #e6eaee;
  z-index: 1;
  font-size: 15px;
}

.btn-flip-icon .btn,
.btn-flip-icon button {
  outline: none;
  border: none;
  background: transparent;
}

.form-control:focus {
  box-shadow: none;
}

.form-control::-moz-placeholder {
  color: #c0cad0;
  opacity: 1;
  /* Firefox */
}

.form-control::placeholder {
  color: #c0cad0;
  opacity: 1;
  /* Firefox */
}

.fw-bold ::-moz-placeholder, .fw-bold::-moz-placeholder {
  font-weight: 600;
}

.fw-bold ::placeholder,
.fw-bold::placeholder {
  font-weight: 600;
}

::-moz-placeholder {
  color: #c0cad0;
}

::placeholder {
  color: #c0cad0;
}

.hdd-arrow span.select2-selection__arrow {
  display: none;
}

/*-------------Add Adult & Child ------------*/
.guests-input {
  position: relative;
  margin: 0 auto;
  border: 1px solid #dadfe6;
  border-radius: 0.375rem;
  display: flex;
  align-items: center;
  padding-left: 5px;
}

.booking-form__input.guests-input button {
  position: relative;
  width: 100%;
  margin: 0px;
  padding: 3px 6px;
  padding-left: 0;
  background-color: #fff;
  border: none;
  text-overflow: ellipsis;
  font-size: 12px;
  transition: border-color 0.2s ease;
  text-align: left;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: #6e7b8d;
  font-weight: 500;
  min-width: 10rem;
}

.booking-form__input.guests-input.mixer-auto {
  padding: 0;
}

.booking-form__input.guests-input.mixer-auto > button {
  padding: 0;
  height: 60px;
  border-radius: 0.375rem;
  border-color: #dadfe6;
  width: 100%;
  font-size: 14px;
  padding-left: 12px;
  color: #041a28;
}

.booking-form__input.guests-input.mixer-auto > button:before,
.booking-form__input.guests-input.mixer-auto > button:after {
  display: none;
}

.guests-input button {
  cursor: pointer;
}

.guests-input button:after,
.guests-input button:before {
  content: " ";
  width: 8px;
  height: 1px;
  border-radius: 2px;
  background-color: #667a8b;
  position: absolute;
  top: 10px;
}

.guests-input button:before {
  transform: rotate(-45deg);
  right: 5px;
}

.guests-input button:after {
  transform: rotate(45deg);
  right: 10px;
}

.guests-input button.open:before {
  transform: rotate(45deg);
}

.guests-input button.open:after {
  transform: rotate(-45deg);
}

.guests-input__options {
  position: absolute;
  z-index: 3;
  top: 2rem;
  width: 100%;
  background-color: #fff;
  box-shadow: rgba(72, 72, 72, 0.2) 0px 15px 20px;
  border-radius: 0.4rem;
  overflow: hidden;
  height: 0;
  opacity: 0;
  min-width: 18rem;
  transition: all 0.1s linear;
}

.booking-form__input.guests-input.mixer-auto .guests-input__options {
  top: 85px;
  padding-top: 1rem;
}

.guests-input__options.open {
  opacity: 1;
  height: auto;
  min-height: 200px;
}

.guests-input__options > div {
  padding: 10px 0;
  text-align: center;
}

.guests-input__options > div:first-child,
.guests-input__options > div {
  padding-top: 35px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 1rem 2rem;
}

.guests-input__options > div:last-child {
  padding-bottom: 35px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.guests-input__ctrl {
  display: inline-block;
  border: 2px solid var(--bs-primary-bg-subtle);
  font-size: 13px;
  color: var(--bs-primary);
  padding: 2px 7px;
  line-height: 20px;
  border-radius: 0.2rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  transition: all 0.2s ease;
}

.guests-input__ctrl.disabled {
  color: #d8d8d8;
  border-color: #d9dee5;
  cursor: default;
}

.guests-input__value {
  display: inline-block;
  padding: 0 10px;
  width: 100px;
  cursor: default;
}

.guests-input__value span {
  display: inline-block;
  padding-right: 5px;
}

/*---------- Custom Dropdown ---------*/
.dropdowns {
  min-width: 12em;
  position: relative;
  margin: 0em;
}

.dropdowns.smaller {
  min-width: 8em;
  position: relative;
  margin: 0em;
}

.selections {
  background: #ffffff;
  color: #6e7b8d;
  border: 1px solid #dadfe6;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 0.2em;
  padding: 3px 6px;
  cursor: pointer;
  transition: background 0.3s;
}

.selections .caret {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 6px solid #6e7b8d;
  transition: 0.3s;
  position: absolute;
  right: 7px;
}

.selections .caret-rotate {
  transform: rotate(180deg);
}

.selections .selected {
  font-size: 12px;
  color: #6e7b8d;
  font-weight: 500;
}

.dropdowns .menu {
  list-style: none;
  padding: 0.2em 0.5em;
  background: #ffffff;
  box-shadow: rgba(72, 72, 72, 0.2) 0px 15px 20px;
  border-radius: 0.5em;
  color: #6e7b8d;
  position: absolute;
  top: 2em;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
  opacity: 0;
  display: none;
  transition: 0.2s;
  z-index: 1;
}

.dropdowns .menu li {
  padding: 0.2em 0.5rem;
  margin: 0.3em 0;
  border-radius: 0.2em;
  cursor: pointer;
}

.dropdowns .menu-open {
  display: block;
  opacity: 1;
}

.dropdowns .menu-open .active {
  background: var(--bs-primary-bg-subtle);
  color: var(--bs-primary);
}

.search-wrap.with-label .form-group label {
  position: absolute;
  top: -9px;
  left: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: calc(100% - 16px);
  font-size: 12px;
  background: #ffffff;
  padding: 0 4px;
  z-index: 2;
}

.search-wrap.with-label .form-group {
  position: relative;
}

.search-wrap.with-label .form-group label {
  font-size: 12px;
  color: #4e5f70;
  letter-spacing: 0;
  text-align: left;
  line-height: 18px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  font-weight: 500;
}

.form-check {
  min-height: 0.8rem;
  padding-left: 1.7rem;
}

.form-check-input {
  width: 1.1rem;
  height: 1.1rem;
  margin-top: 0.19em;
  margin-left: -1.7rem !important;
}

.form-check.lg {
  min-height: 1rem;
  padding-left: 2rem;
  margin-bottom: 0.5rem;
}

.form-check.lg .form-check-input {
  width: 1.4rem;
  height: 1.4rem;
  margin-top: 0.1em;
  margin-left: -2rem !important;
}

.form-check-input:checked {
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
}

.form-check-input:focus {
  border-color: #ffdfdd;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(255, 223, 221);
}

/*--------------------------------------------------
 Common Elentns 
---------------------------------------------------*/
.progress {
  margin-bottom: 1.5em;
}

p,
ul,
ol,
dl,
dt,
dd,
blockquote,
address {
  margin: 0 0 10px;
}

.full-width {
  width: 100%;
}

.explore-content p {
  font-size: 19px;
  font-family: "Lora", serif;
  font-style: italic;
}

.shadow-0 {
  box-shadow: none !important;
}

.ovr-top {
  margin-top: -60px;
  position: relative;
  z-index: 1;
}

.shadow-wrap {
  box-shadow: 0 8px 16px 0 rgba(15, 41, 77, 0.06);
  -webkit-box-shadow: 0 8px 16px 0 rgba(15, 41, 77, 0.06);
  -moz-box-shadow: 0 8px 16px 0 rgba(15, 41, 77, 0.06);
}

.dropdown-item {
  padding: 8px 1rem;
  color: #607394;
  font-size: 15.5px;
}

/*---------- Common Elentns: Price Changer Styles ------------*/
.js-yearlypricing {
  display: none;
}

.js-yearlypricing,
.js-montlypricing {
  justify-content: center;
}

.js-yearlypricing sup,
.js-montlypricing sup {
  top: 15px;
}

.slideToggle {
  display: flex;
  justify-content: center;
  margin: 25px 0;
}
.slideToggle i {
  margin: 0 15px;
}
.slideToggle .form-switch {
  align-items: center;
  display: flex;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  justify-content: space-between;
}
.slideToggle .form-switch i {
  position: relative;
  display: inline-block;
  width: 112px;
  height: 44px;
  border: 4px solid #ffffff;
  border-radius: 50px;
  transition: all 0.3s linear;
}
.slideToggle .form-switch i:after {
  content: "";
  position: absolute;
  left: 0;
  width: 46px;
  height: 30px;
  background-color: var(--bs-primary);
  border-radius: 15px;
  transform: translate3d(4px, 3px, 0);
  transition: all 0.2s ease-in-out;
}

.slideToggle .form-switch input {
  display: none;
}

.slideToggle .form-switch input:checked + i {
  background-color: var(--bs-primary-bg-subtle);
  border-color: #ffa6a1;
}

.slideToggle .form-switch input:checked + i::after {
  transform: translate3d(54px, 3px, 0);
}

.no-wrap td,
.no-wrap th {
  white-space: nowrap;
}

.circle {
  border-radius: 100%;
}

.offcanvas-body {
  display: block !important;
}

/*-------------- Common Elentns: Custom Height ------------*/
.ht-10 {
  height: 10px;
}

.ht-20 {
  height: 20px;
}

.ht-30 {
  height: 30px;
}

.ht-40 {
  height: 40px;
}

.ht-50 {
  height: 50px;
}

.ht-60 {
  height: 60px;
}

.ht-70 {
  height: 70px;
}

.ht-80 {
  height: 80px;
}

.ht-80 {
  height: 80px;
}

.ht-100 {
  height: 100px;
}

.ht-110 {
  height: 110px;
}

.ht-120 {
  height: 120px;
}

.ht-130 {
  height: 130px;
}

.ht-140 {
  height: 140px;
}

.ht-150 {
  height: 150px;
}

.ht-160 {
  height: 160px;
}

.ht-170 {
  height: 170px;
}

.ht-180 {
  height: 180px;
}

.ht-190 {
  height: 190px;
}

.ht-200 {
  height: 200px;
}

.ht-400 {
  height: 400px;
}

.ht-500 {
  height: 500px;
}

.ht-100 {
  height: 100%;
  min-height: 580px;
}

.h-100 {
  height: 100vh;
}

.h-05 {
  height: 0.1rem !important;
}

.h-1 {
  height: 0.25rem !important;
}

.h-2 {
  height: 0.5rem !important;
}

.h-3 {
  height: 0.75rem !important;
}

.h-4 {
  height: 1rem !important;
}

.h-5 {
  height: 1.25rem !important;
}

.h-6 {
  height: 1.5rem !important;
}

.h-7 {
  height: 1.75rem !important;
}

.h-8 {
  height: 2rem !important;
}

.h-9 {
  height: 2.25rem !important;
}

.h-10 {
  height: 2.5rem !important;
}

.h-11 {
  height: 2.75rem !important;
}

.h-12 {
  height: 3rem !important;
}

.h-13 {
  height: 3.25rem !important;
}

.h-14 {
  height: 3.75rem !important;
}

.h-15 {
  height: 4rem !important;
}

.h-16 {
  height: 4.25rem !important;
}

.h-17 {
  height: 4.5rem !important;
}

.h-18 {
  height: 4.75rem !important;
}

.h-19 {
  height: 5rem !important;
}

.h-20 {
  height: 5.25rem !important;
}

.h-25 {
  height: 6rem !important;
}

.h-30 {
  height: 7rem !important;
}

.h-35 {
  height: 8rem !important;
}

.h-40 {
  height: 9rem !important;
}

.h-45 {
  height: 10rem !important;
}

.h-50 {
  height: 15rem !important;
}

/*------------- Common Elentns: Custom Width -------------*/
.w-1 {
  width: 0.25rem !important;
}

.w-2 {
  width: 0.5rem !important;
}

.w-3 {
  width: 0.75rem !important;
}

.w-4 {
  width: 1rem !important;
}

.w-5 {
  width: 1.25rem !important;
}

.w-6 {
  width: 1.5rem !important;
}

.w-7 {
  width: 1.75rem !important;
}

.w-8 {
  width: 2rem !important;
}

.w-9 {
  width: 2.25rem !important;
}

.w-10 {
  width: 2.5rem !important;
}

.w-11 {
  width: 2.75rem !important;
}

.w-12 {
  width: 3rem !important;
}

.w-13 {
  width: 3.25rem !important;
}

.w-14 {
  width: 3.75rem !important;
}

.w-15 {
  width: 4rem !important;
}

.w-16 {
  width: 4.25rem !important;
}

.w-17 {
  width: 4.5rem !important;
}

.w-18 {
  width: 4.75rem !important;
}

.w-19 {
  width: 5rem !important;
}

.w-20 {
  width: 5.25rem !important;
}

.w-25 {
  width: 6rem !important;
}

.w-30 {
  width: 7rem !important;
}

.w-35 {
  width: 8rem !important;
}

.w-40 {
  width: 9rem !important;
}

.w-45 {
  width: 10rem !important;
}

.w-50s {
  width: 15rem !important;
}

.start-10 {
  left: 10% !important;
}

.start-20 {
  left: 20% !important;
}

.start-30 {
  left: 30% !important;
}

.start-40 {
  left: 40% !important;
}

.start-50 {
  left: 50% !important;
}

.start-60 {
  left: 60% !important;
}

.start-70 {
  left: 70% !important;
}

.start-80 {
  left: 80% !important;
}

.start-90 {
  left: 90% !important;
}

.end-10 {
  right: 10% !important;
}

.end-20 {
  right: 20% !important;
}

.end-30 {
  right: 30% !important;
}

.end-40 {
  right: 40% !important;
}

.end-50 {
  right: 50% !important;
}

.end-60 {
  right: 60% !important;
}

.end-70 {
  right: 70% !important;
}

.end-80 {
  right: 80% !important;
}

.end-90 {
  right: 90% !important;
}

/*---------- Common Elentns: Extra Margine ------------*/
.mt-n2 {
  margin-top: -2rem !important;
}

.mt-n4 {
  margin-top: -4rem !important;
}

.mt-n6 {
  margin-top: -6rem !important;
}

.mt-n8 {
  margin-top: -8rem !important;
}

.mt-n10 {
  margin-top: -10rem !important;
}

/*------------ Common Elentns: Custom Avatar ----------------*/
.avatar {
  display: inline-block !important;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  line-height: 1.7;
  letter-spacing: -0.01rem;
  border-radius: 100%;
  height: 3rem;
  width: 3rem;
}

.avatar-xl {
  height: 5rem;
  width: 5rem;
}

.avatar-xl {
  height: 8rem;
  width: 8rem;
}

.avatar-lg {
  height: 3rem;
  width: 3rem;
}

.avatar-md {
  height: 2rem;
  width: 2rem;
}

.avatar-sm {
  height: 1rem;
  width: 1rem;
}

.avatar-img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  position: relative;
}

/*------------ Common Elentns: Social Links ---------------*/
.color--facebook {
  color: #3b5998 !important;
}

.color--twitter {
  color: #00aced !important;
}

.color--googleplus {
  color: #dd4b39 !important;
}

.color--instagram {
  color: #125688 !important;
}

.color--pinterest {
  color: #cb2027 !important;
}

.color--dribbble {
  color: #ea4c89 !important;
}

.color--behance {
  color: #053eff !important;
}

.color--linkedin {
  color: #1685b9 !important;
}

.color--whatsapp {
  color: #4caf50 !important;
}

.bg--facebook {
  background: #3b5998 !important;
  color: #fff !important;
}

.bg--twitter {
  background: #00aced !important;
  color: #fff !important;
}

.bg--googleplus {
  background: #dd4b39 !important;
  color: #fff !important;
}

.bg--instagram {
  background: #125688 !important;
  color: #fff !important;
}

.bg--pinterest {
  background: #cb2027 !important;
  color: #fff !important;
}

.bg--dribbble {
  background: #ea4c89 !important;
  color: #fff !important;
}

.bg--behance {
  background: #053eff !important;
  color: #fff !important;
}

.bg--linkedin {
  background: #1685b9 !important;
  color: #fff !important;
}

.btn--facebook {
  background: #ffffff !important;
  border: 1px solid #3b5998 !important;
  color: #3b5998 !important;
}
.btn--facebook:hover {
  background: #3b5998 !important;
  border: 1px solid #3b5998 !important;
  color: #ffffff !important;
}
.btn--facebookfocus {
  background: #3b5998 !important;
  border: 1px solid #3b5998 !important;
  color: #ffffff !important;
}
.btn--facebook:active {
  background: #3b5998 !important;
  border: 1px solid #3b5998 !important;
  color: #ffffff !important;
}

.btn--twitter {
  background: #ffffff !important;
  border: 1px solid #00aced !important;
  color: #00aced !important;
}
.btn--twitter:hover {
  background: #00aced !important;
  border: 1px solid #00aced !important;
  color: #ffffff !important;
}
.btn--twitter:focus {
  background: #00aced !important;
  border: 1px solid #00aced !important;
  color: #ffffff !important;
}
.btn--twitter:active {
  background: #00aced !important;
  border: 1px solid #00aced !important;
  color: #ffffff !important;
}

.btn--googleplus {
  background: #ffffff !important;
  border: 1px solid #dd4b39 !important;
  color: #dd4b39 !important;
}
.btn--googleplus:hover {
  background: #dd4b39 !important;
  border: 1px solid #dd4b39 !important;
  color: #ffffff !important;
}
.btn--googleplus:focus {
  background: #dd4b39 !important;
  border: 1px solid #dd4b39 !important;
  color: #ffffff !important;
}
.btn--googleplus:active {
  background: #dd4b39 !important;
  border: 1px solid #dd4b39 !important;
  color: #ffffff !important;
}

.btn--instagram {
  background: #ffffff !important;
  border: 1px solid #125688 !important;
  color: #125688 !important;
}
.btn--instagram:hover {
  background: #125688 !important;
  border: 1px solid #125688 !important;
  color: #ffffff !important;
}
.btn--instagram:focus {
  background: #125688 !important;
  border: 1px solid #125688 !important;
  color: #ffffff !important;
}
.btn--instagram:active {
  background: #125688 !important;
  border: 1px solid #125688 !important;
  color: #ffffff !important;
}

.btn--pinterest {
  background: #ffffff !important;
  border: 1px solid #cb2027 !important;
  color: #cb2027 !important;
}
.btn--pinterest:hover {
  background: #cb2027 !important;
  border: 1px solid #cb2027 !important;
  color: #ffffff !important;
}
.btn--pinterest:focus {
  background: #cb2027 !important;
  border: 1px solid #cb2027 !important;
  color: #ffffff !important;
}
.btn--pinterest:active {
  background: #cb2027 !important;
  border: 1px solid #cb2027 !important;
  color: #ffffff !important;
}

.btn--dribbble {
  background: #ffffff !important;
  border: 1px solid #ea4c89 !important;
  color: #ea4c89 !important;
}
.btn--dribbble:hover {
  background: #ea4c89 !important;
  border: 1px solid #ea4c89 !important;
  color: #ffffff !important;
}
.btn--dribbble:focus {
  background: #ea4c89 !important;
  border: 1px solid #ea4c89 !important;
  color: #ffffff !important;
}
.btn--dribbble:active {
  background: #ea4c89 !important;
  border: 1px solid #ea4c89 !important;
  color: #ffffff !important;
}

.btn--behance {
  background: #ffffff !important;
  border: 1px solid #053eff !important;
  color: #053eff !important;
}
.btn--behance:hover {
  background: #053eff !important;
  border: 1px solid #053eff !important;
  color: #ffffff !important;
}
.btn--behance:focus {
  background: #053eff !important;
  border: 1px solid #053eff !important;
  color: #ffffff !important;
}
.btn--behance:active {
  background: #053eff !important;
  border: 1px solid #053eff !important;
  color: #ffffff !important;
}

.btn--linkedin {
  background: #ffffff !important;
  border: 1px solid #1685b9 !important;
  color: #1685b9 !important;
}
.btn--linkedin:hover {
  background: #1685b9 !important;
  border: 1px solid #1685b9 !important;
  color: #ffffff !important;
}
.btn--linkedin:focus {
  background: #1685b9 !important;
  border: 1px solid #1685b9 !important;
  color: #ffffff !important;
}
.btn--linkedin:active {
  background: #1685b9 !important;
  border: 1px solid #1685b9 !important;
  color: #ffffff !important;
}

.height-100,
.height-90,
.height-80,
.height-70,
.height-60,
.height-50,
.height-40,
.height-30,
.height-20,
.height-10 {
  height: auto;
  padding: 5em 0;
}

/*---------- Common Elentns: Background & Images ---------------*/
.bg-img-holder {
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  background-size: cover !important;
  background-position: 50% 50% !important;
  z-index: 0;
  transition: opacity 0.3s linear;
  -webkit-transition: opacity 0.3s linear;
  opacity: 0;
  background: #252525;
}

.bg-img-holder:not([class*=col-]) {
  width: 100%;
}

.bg-img-holder.background--bottom {
  background-position: 50% 100% !important;
}

.bg-img-holder.background--top {
  background-position: 50% 0% !important;
}

.image--light .bg-img-holder {
  background: none;
}

.bg-img-holder img {
  display: none;
}

.imagebg.border--round {
  overflow: hidden;
}

[data-overlay] {
  position: relative;
}
[data-overlay]:before {
  position: absolute;
  content: "";
  background: #020d16;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}

[data-overlay].image--light:before {
  background: #fff;
}

[data-overlay].bg--primary:before {
  background: #4a90e2;
}

[data-overlay="1"]:before {
  opacity: 0.1;
}

[data-overlay="2"]:before {
  opacity: 0.2;
}

[data-overlay="3"]:before {
  opacity: 0.3;
}

[data-overlay="4"]:before {
  opacity: 0.4;
}

[data-overlay="5"]:before {
  opacity: 0.5;
}

[data-overlay="6"]:before {
  opacity: 0.6;
}

[data-overlay="7"]:before {
  opacity: 0.7;
}

[data-overlay="8"]:before {
  opacity: 0.8;
}

[data-overlay="9"]:before {
  opacity: 0.9;
}

[data-overlay="10"]:before {
  opacity: 1;
}

[data-overlay="0"]:before {
  opacity: 0;
}

[data-scrim-bottom] {
  position: relative;
}

[data-scrim-bottom]:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 80%;
  /* FF3.6+ */
  /* Chrome,Safari4+ */
  /* Chrome10+,Safari5.1+ */
  /* Opera 11.10+ */
  /* IE10+ */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #252525 100%);
  bottom: 0;
  left: 0;
  z-index: 1;
  backface-visibility: hidden;
}

[data-scrim-bottom]:not(.image--light) h1,
[data-scrim-bottom]:not(.image--light) h2,
[data-scrim-bottom]:not(.image--light) h3,
[data-scrim-bottom]:not(.image--light) h4,
[data-scrim-bottom]:not(.image--light) h5,
[data-scrim-bottom]:not(.image--light) h6 {
  color: #a5a5a5;
  color: #f1f1f1;
}

[data-scrim-bottom]:not(.image--light) p,
[data-scrim-bottom]:not(.image--light) span,
[data-scrim-bottom]:not(.image--light) ul {
  color: #e6e6e6;
}

[data-scrim-bottom].image--light:before {
  background: #fff;
}

[data-scrim-bottom="1"]:before {
  opacity: 0.1;
}

[data-scrim-bottom="2"]:before {
  opacity: 0.2;
}

[data-scrim-bottom="3"]:before {
  opacity: 0.3;
}

[data-scrim-bottom="4"]:before {
  opacity: 0.4;
}

[data-scrim-bottom="5"]:before {
  opacity: 0.5;
}

[data-scrim-bottom="6"]:before {
  opacity: 0.6;
}

[data-scrim-bottom="7"]:before {
  opacity: 0.7;
}

[data-scrim-bottom="8"]:before {
  opacity: 0.8;
}

[data-scrim-bottom="9"]:before {
  opacity: 0.9;
}

[data-scrim-bottom="10"]:before {
  opacity: 1;
}

[data-scrim-top] {
  position: relative;
}

[data-scrim-top]:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 80%;
  /* FF3.6+ */
  /* Chrome,Safari4+ */
  /* Chrome10+,Safari5.1+ */
  /* Opera 11.10+ */
  /* IE10+ */
  background: linear-gradient(to bottom, #252525 0%, rgba(0, 0, 0, 0) 100%);
  top: 0;
  left: 0;
  z-index: 1;
}

[data-scrim-top]:not(.image--light) h1,
[data-scrim-top]:not(.image--light) h2,
[data-scrim-top]:not(.image--light) h3,
[data-scrim-top]:not(.image--light) h4,
[data-scrim-top]:not(.image--light) h5,
[data-scrim-top]:not(.image--light) h6 {
  color: #fff;
}

[data-scrim-top]:not(.image--light) p,
[data-scrim-top]:not(.image--light) span,
[data-scrim-top]:not(.image--light) ul {
  color: #e6e6e6;
}

[data-scrim-top].image--light:before {
  background: #fff;
}

[data-scrim-top="1"]:before {
  opacity: 0.1;
}

[data-scrim-top="2"]:before {
  opacity: 0.2;
}

[data-scrim-top="3"]:before {
  opacity: 0.3;
}

[data-scrim-top="4"]:before {
  opacity: 0.4;
}

[data-scrim-top="5"]:before {
  opacity: 0.5;
}

[data-scrim-top="6"]:before {
  opacity: 0.6;
}

[data-scrim-top="7"]:before {
  opacity: 0.7;
}

[data-scrim-top="8"]:before {
  opacity: 0.8;
}

[data-scrim-top="9"]:before {
  opacity: 0.9;
}

[data-scrim-top="10"]:before {
  opacity: 1;
}

.imagebg {
  position: relative;
}

.imagebg .container {
  z-index: 2;
}

.imagebg .container:not(.pos-absolute) {
  position: relative;
}

.imagebg:not(.image--light) h1,
.imagebg:not(.image--light) h2,
.imagebg:not(.image--light) h3,
.imagebg:not(.image--light) h4,
.imagebg:not(.image--light) h5,
.imagebg:not(.image--light) h6,
.imagebg:not(.image--light) p,
.imagebg:not(.image--light) ul,
.imagebg:not(.image--light) blockquote {
  color: #fff;
}

.imagebg:not(.image--light) .bg--white h1,
.imagebg:not(.image--light) .bg--white h2,
.imagebg:not(.image--light) .bg--white h3,
.imagebg:not(.image--light) .bg--white h4,
.imagebg:not(.image--light) .bg--white h5,
.imagebg:not(.image--light) .bg--white h6 {
  color: #252525;
}

.imagebg:not(.image--light) .bg--white p,
.imagebg:not(.image--light) .bg--white ul {
  color: #666666;
}

div[data-overlay] h1,
div[data-overlay] h2,
div[data-overlay] h3,
div[data-overlay] h4,
div[data-overlay] h5,
div[data-overlay] h6 {
  color: #fff;
}

div[data-overlay] p {
  color: #fff;
}

.parallax {
  overflow: hidden;
}

/**! 07. Icons **/
.icon {
  line-height: 1em;
  font-size: 3.14285714em;
}

.imagebg:not(.image--light) span {
  color: #fff;
}

/*---------- Common Elentns: Background Settings ---------*/
.object-fit {
  -o-object-fit: cover;
     object-fit: cover;
}

.bg-cover {
  background-size: cover !important;
  background-position: center !important;
}

.image-bg {
  background-size: cover !important;
  background-position: center !important;
}

.default-bg {
  background-size: initial !important;
  background-position: initial !important;
}

.image-bg-wrap {
  background-size: cover !important;
  background-position: center !important;
  position: relative;
}
.image-bg-wrapbefore {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #182538;
  display: block;
  content: "";
  opacity: 0.8;
}

.thumb-sm {
  height: 32px;
  width: 32px;
}

.thumb-md {
  height: 48px;
  width: 48px;
}

.thumb-lg {
  height: 88px;
  width: 88px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.square--20 {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.square--30 {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.square--40 {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.square--50 {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.square--60 {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.square--70 {
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.square--80 {
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.square--90 {
  width: 90px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.square--100 {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hide {
  display: none;
}

.text-xs {
  font-size: 10px;
}

.text-sm {
  font-size: 12px;
}

.text-md {
  font-size: 14px;
}

/*----------- Common Elentns: Card Sedign ------------*/
.card {
  border: none;
  overflow: hidden;
  width: 100%;
  background: #ffffff;
}

.card-header {
  background: #ffffff;
  padding: 0.8rem 1rem;
  border-color: #e2e8ec;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.card-header h4 {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}
.card-header h5 {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}
.card-header h6 {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}

/*---------- Common Elentns: Custom Background Colors ------------*/
.bg-seegreen {
  background-color: var(--bs-cyan) !important;
}

.bg-purple {
  background-color: var(--bs-purple) !important;
}

.bg-white {
  background-color: var(--bs-white) !important;
}

.bg-light-primary {
  background-color: var(--bs-primary-bg-subtle) !important;
}

.bg-light-success {
  background-color: var(--bs-success-bg-subtle) !important;
}

.bg-light-info {
  background-color: var(--bs-info-bg-subtle) !important;
}

.bg-light-warning {
  background-color: var(--bs-warning-bg-subtle) !important;
}

.bg-light-danger {
  background-color: var(--bs-danger-bg-subtle) !important;
}

.bg-light-purple {
  background-color: #e3e3ff !important;
}

.bg-light-seegreen {
  background-color: #b2e7dc !important;
}

.bg-light-dark {
  background-color: rgba(4, 27, 45, 0.1) !important;
}

.bg-transparents {
  background-color: rgba(255, 255, 255, 0.1);
}

.bg--graysmoke {
  background-image: linear-gradient(180deg, rgba(244, 244, 249, 0.8) 50%, rgba(244, 244, 249, 0.05) 100%);
}

.shape--bg:after {
  width: 48%;
  height: 72%;
  top: 21%;
  left: 52%;
}

.shape--bg:before {
  width: 48%;
  height: 72%;
  top: 21%;
  right: 52%;
}

/*----------- Common Elentns: Custom List Styles ----------*/
ul.simple-list,
ul.colored-list,
ul.simple {
  padding: 0;
  margin: 0;
}

ul.simple-list li,
ul.colored-list li {
  list-style: none;
  position: relative;
  color: rgba(0, 44, 63, 0.6);
  font-weight: 500;
  padding: 0.2rem 0rem 0.2rem 1.4rem;
}

ul.simple-list li:before {
  content: "\f192";
  font-family: "Font Awesome 6 Free";
  position: absolute;
  left: 0;
}

ul.colored-list li:before {
  content: "\f192";
  font-family: "Font Awesome 6 Free";
  color: #cd2c22;
  position: absolute;
  left: 0;
}

/*---------- Common Elentns: Info Styles --------------*/
.label {
  padding: 4px 15px;
  font-weight: 500;
  border-radius: 4px;
  font-size: 75%;
}

.label-seegreen {
  background-color: var(--bs-cyan);
}

.label-dark {
  background-color: var(--bs-dark);
}

.label-inverse {
  background-color: var(--bs-gray-700);
}

.label-white {
  background-color: var(--bs-white);
}

.label-light-primary {
  background-color: var(--bs-primary-bg-subtle);
  color: var(--bs-primary);
}

.label-light-success {
  background-color: var(--bs-success-bg-subtle);
  color: var(--bs-success);
}

.label-light-info {
  background-color: var(--bs-info-bg-subtle);
  color: var(--bs-info);
}

.label-light-warning {
  background-color: var(--bs-warning-bg-subtle);
  color: var(--bs-warning-bg);
}

.label-light-danger {
  background-color: var(--bs-danger-bg-subtle);
  color: var(--bs-danger-bg);
}

.label-light-purple {
  background-color: #e3e3ff;
  color: var(--bs-purple);
}

.label-light-seegreen {
  background-color: #b2e7dc;
  color: var(--bs-cyan);
}

.label-light-dark {
  background-color: rgba(4, 27, 45, 0.12);
  color: #041b2d;
}

.label-light-inverse {
  background-color: rgba(62, 69, 85, 0.1);
  color: #3e4555;
}

/*--------- Common Elentns: Table Custom Settings -----------*/
.table tr th,
.table tr td {
  padding: 12px 15px;
  vertical-align: middle;
}

.table.tbl-big tr th,
.table.tbl-big tr td {
  padding: 20px 15px;
}

.table.tbl-big.center tr th,
.table.tbl-big.center tr td {
  padding: 20px 15px;
  text-align: center;
}

table.table tr th {
  font-weight: 600;
}

/*----------- Common Elentns: Custom badge Styles ----------*/
.badge {
  font-weight: 400;
}

.badge-xs {
  font-size: 9px;
}

.badge-xs,
.badge-sm {
  transform: translate(0, -2px);
}

.badge-purple {
  background-color: var(--bs-purple);
}

.badge-seegreen {
  background-color: var(--bs-cyan);
}

.badge-dark {
  background-color: var(--bs-dark);
}

/*----------- Common Elentns: Custom Alert Styles ----------*/
.alert-primary {
  color: var(--bs-primary);
  background-color: var(--bs-primary-bg-subtle);
  border-color: var(--bs-primary);
}
.alert-primary .alert-link {
  color: var(--bs-primary-bg-dark);
}

.alert-secondary {
  color: var(--bs-dark);
  background-color: var(--bs-secondary-bg-subtle);
  border-color: var(--bs-secondary);
}
.alert-secondary .alert-link {
  color: var(--bs-dark);
}

.alert-success {
  color: var(--bs-success);
  background-color: var(--bs-success-bg-subtle);
  border-color: var(--bs-success);
}
.alert-success .alert-link {
  color: var(--bs-success-bg-dark);
}

.alert-warning {
  color: var(--bs-warning);
  background-color: var(--bs-warning-bg-subtle);
  border-color: var(--bs-warning);
}
.alert-warning .alert-link {
  color: var(--bs-warning-bg-dark);
}

.alert-danger {
  color: var(--bs-danger);
  background-color: var(--bs-danger-bg-subtle);
  border-color: var(--bs-danger);
}
.alert-danger .alert-link {
  color: var(--bs-danger-bg-dark);
}

.alert-info {
  color: var(--bs-info);
  background-color: var(--bs-info-bg-subtle);
  border-color: var(--bs-info);
}
.alert-info .alert-link {
  color: var(--bs-info-bg-dark);
}

.alert-dark {
  color: var(--bs-white);
  background-color: var(--bs-dark);
  border-color: var(--bs-dark);
}
.alert-dark .alert-link {
  color: var(--bs-white);
}

.alert-seegreen {
  color: var(--bs-cyan);
  background-color: var(--bs-cyan-bg-subtle);
  border-color: var(--bs-cyan);
}
.alert-seegreen .alert-link {
  color: var(--bs-cyan-bg-dark);
}

.alert-purple {
  color: var(--bs-purple);
  background-color: var(--bs-purple-bg-subtle);
  border-color: var(--bs-purple);
}
.alert-purple .alert-link {
  color: var(--bs-purple-bg-dark);
}

/*-------- Common Elentns: video Buttons Styles -------------*/
.cover .video-block:not(:first-child):not(:last-child) {
  margin: 2.77em 0;
}

.video-play-icon {
  width: 6.42857143em;
  height: 6.42857143em;
  border-radius: 50%;
  position: relative;
  z-index: 2;
  display: inline-block;
  border: 2px solid #ffffff;
  cursor: pointer;
  line-height: 6em;
  background: #ffffff;
  box-shadow: 0px 0px 0px 15px rgba(255, 255, 255, 0.2);
  -webkit-box-shadow: 0px 0px 0px 15px rgba(255, 255, 255, 0.2);
}
.video-play-icon a {
  font-size: 3em;
  color: var(--bs-primary);
}

.btn-secondary {
  background: #f3f6fa;
  border-color: #f3f6fa;
  color: #738594;
  letter-spacing: 0.1px;
}

.btn-check + .btn:hover,
.btn-check + .btn:focus {
  color: var(--bs-white);
  cursor: pointer;
  background: var(--bs-primary);
  border-color: var(--bs-primary);
}

.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn-check:active {
  color: var(--bs-white);
  background: var(--bs-primary);
  border-color: var(--bs-primary);
}

/*----------- Common Elentns: Custom Pagination Design ------------*/
.pagination {
  display: table;
  padding-left: 0;
  border-radius: 4px;
  margin: 0px auto 0 !important;
}

.pagination > li > a,
.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  margin: 5px;
  padding: 0.6rem 0.9rem;
  text-decoration: none;
  background-color: var(--bs-white);
  border-radius: 2px;
  text-align: center;
  line-height: 1;
  border: none;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 15px;
}

.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover,
.pagination > li > a:focus,
.pagination > li > a:hover {
  z-index: 2;
  cursor: pointer;
  border-color: none;
}

.pagination li:first-child a {
  background: transparent;
  border: none;
  border-radius: 2px;
}

.pagination li:last-child a {
  background: transparent;
  border: none;
  border-radius: 2px;
}

.pagination > li {
  display: inline;
}

.page-item.active .page-link {
  z-index: 2;
  border-color: none;
}

.active > .page-link,
.page-link.active {
  z-index: 3;
  color: var(--bs-white) !important;
  background-color: var(--bs-primary) !important;
  border-color: var(--bs-primary) !important;
}

.page-link:focus {
  box-shadow: var(--bs-primary-bg-subtle);
}

/*----------- Common Elentns: price Range Styles --------------*/
.irs--round .irs-bar {
  background-color: var(--bs-primary);
}

.irs--round .irs-handle {
  background-color: var(--bs-primary);
  border-color: #ffffff;
}

.irs--round .irs-handle.state_hover,
.irs--round .irs-handle:hover {
  background-color: #21313d;
}

.irs--round .irs-handle {
  width: 16px;
  height: 16px;
  top: 29px;
}

.irs--round .irs-from,
.irs--round .irs-to,
.irs--round .irs-single {
  background: transparent;
  color: #21313d;
}

.irs--round .irs-from:before,
.irs--round .irs-to:before,
.irs--round .irs-single:before,
.irs--round .irs-min,
.irs--round .irs-max {
  display: none;
}

/*------------- Common Elentns: Accordion Styles ---------------*/
.accordion-button:focus {
  z-index: 3;
  border-color: none;
  outline: 0;
  box-shadow: none;
}

.bi {
  width: 1em;
  height: 1em;
  fill: currentcolor;
}

.accordion-button {
  font-weight: 500;
  color: #00345b;
}

.accordion-button:not(.collapsed) {
  color: var(--bs-primary);
  background-color: rgba(205, 44, 34, 0.1);
  box-shadow: none;
}

.searchBar-single ul li .btn-sm {
  height: auto;
  padding: 0.5rem 0.4rem;
}

.accordion-flush .accordion-item,
.accordion .accordion-item {
  border-radius: 0.2rem;
  overflow: hidden;
  margin-bottom: 1rem;
  border: none;
}

.accordion-button::after {
  content: "\f0d8";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  background-image: none;
  width: 28px;
  height: 28px;
  display: flex;
  color: var(--bs-primary);
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all ease 0.4s;
  background: var(--bs-primary-bg-subtle);
}

.accordion-button:not(.collapsed)::after {
  content: "\f0d7";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  background-image: none;
  width: 28px;
  height: 28px;
  display: flex;
  color: var(--bs-primary);
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all ease 0.4s;
  background: rgba(205, 44, 34, 0.11);
}

/*------------ Common Elentns: Nav Tabs & Tabs Styles --------------*/
.nav-pills .nav-link {
  font-weight: 500;
  padding: 0.8rem 1.6rem;
  background: #131b24;
  color: #ffffff;
  font-weight: 500;
  margin-right: 10px;
}

.nav-pills.mid .nav-link {
  padding: 0.6rem 1.9rem;
  font-size: 14px;
}

.nav-pills.small .nav-link {
  padding: 0.5rem 0.8rem;
  font-size: 13px;
}

.nav-pills.medium .nav-link {
  padding: 0.7rem 1.2rem;
  font-size: 13px;
}

.nav-pills.spacing .nav-link {
  margin-bottom: 10px;
}

.nav-pills.lights .nav-link {
  background: rgba(245, 246, 247, 0.1);
  color: #fff;
}

.nav-pills.lights.blukker .nav-link:not(.active) {
  background: transparent;
}

.nav-pills.lights .nav-link.active,
.nav-pills.lights .show > .nav-link {
  background: #ffffff;
  color: var(--bs-primary) !important;
}

.nav-pills.primary-soft .nav-link {
  background: var(--bs-primary-bg-subtle);
  color: var(--bs-primary);
}

.nav-pills.primary-soft .nav-link.active,
.nav-pills.primary-soft .show > .nav-link {
  background: var(--bs-primary);
  color: var(--bs-white) !important;
}

.nav-pills.lights.theme .nav-link.active,
.nav-pills.lights.theme .show > .nav-link {
  background: var(--bs-primary);
  color: #ffffff;
}

.nav-pills.primary .nav-link {
  background: transparent;
  color: var(--bs-primary);
}

.nav-pills.primary .nav-link.active,
.nav-pills.primary .show > .nav-link {
  background: var(--bs-primary);
  color: #ffffff !important;
}

.stry-counter {
  position: relative;
  padding: 2px 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  margin-left: 5px;
  font-weight: 600;
}

.nav-pills.small-jbs-tab .nav-link {
  padding: 0.4rem 1.5rem;
  border-radius: 0.2rem;
  background: #eff1f5;
  color: #525f66;
  font-size: 12px;
}

.nav-pills.small-jbs-tab .nav-link.active,
.nav-pills.small-jbs-tab .show > .nav-link {
  background: #0264d6;
  color: #ffffff;
}

.nav-tabs {
  border-bottom: 2px solid #ebeff2;
}

.nav-tabs .nav-link {
  font-weight: 500;
  font-size: 13px;
  opacity: 1;
  color: #526d83;
  border: none;
  border-bottom: 2px solid transparent;
  margin: 0;
}

.nav-tabs.medium .nav-link {
  padding: 0.9rem 1.4rem;
  font-size: 14px;
}

.nav-tabs.lg .nav-link {
  padding: 1.2rem 1.7rem;
  font-size: 15px;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  color: var(--bs-primary);
  border: none;
  opacity: 1;
  border-bottom: 2px solid var(--bs-primary);
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: var(--bs-primary);
  border: none;
  opacity: 1;
  border-bottom: 2px solid var(--bs-primary);
}

.nav-pills.light-nav .nav-link.active,
.nav-pills.light-nav .show > .nav-link {
  color: #ffffff !important;
  background: var(--bs-primary) !important;
}

.nav-tabs.simple-tabs .nav-link {
  background: var(--bs-dark);
  border-radius: 0;
  border: none;
}

.nav-tabs.simple-tabs .nav-item.show .nav-link,
.nav-tabs.simple-tabs .nav-link.active {
  background: var(--bs-white);
  border: none;
}

.arrow-collapse:before {
  content: "\f0d7";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ebeff2;
  position: absolute;
  right: 0px;
  color: #27353e;
}

.arrow-collapse.collapsed:before {
  content: "\f0d8";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ebeff2;
  position: absolute;
  right: 0px;
  color: #27353e;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #ffffff !important;
  background-color: var(--bs-primary);
}

/*----------- Common Elentns: FLATPICKER CUSTOM STYLES -------------*/
.flatpickr-day.today {
  border-color: var(--bs-primary);
  color: var(--bs-primary);
}

.flatpickr-day.today:hover,
.flatpickr-day.today:focus {
  border-color: var(--bs-primary);
  background: var(--bs-primary);
  color: #fff;
}

.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
  cursor: pointer;
  outline: 0;
  color: var(--bs-primary);
  background: var(--bs-primary-bg-subtle);
  border-color: var(--bs-primary-bg-subtle);
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: var(--bs-primary);
  box-shadow: none;
  border-color: var(--bs-primary);
}

.flatpickr-day.inRange {
  border-radius: 0;
  box-shadow: -5px 0 0 #fbe9e9, 5px 0 0 #fbe9e9;
}

.flatpickr-day.selected.startRange + .endRange,
.flatpickr-day.startRange.startRange + .endRange,
.flatpickr-day.endRange.startRange + .endRange {
  box-shadow: -10px 0 0 #fbe9e9;
}

/*-------------- Common Elentns: BREADCRUMBS STYLES ---------------*/
.breadcrumb.lights a {
  color: #ffffff;
  opacity: 0.8;
}

.breadcrumb.lights .breadcrumb-item + .breadcrumb-item::before {
  color: #ffffff;
  opacity: 0.8;
}

.breadcrumb.lights .breadcrumb-item.active {
  color: #ffffff;
  opacity: 1;
}

.breadcrumb.breadcrumb-dots .breadcrumb-item + .breadcrumb-item::before {
  content: "•";
  font-size: 22px;
  line-height: 0;
  height: 100%;
  display: flex;
  align-items: center;
}

/*------------- Common Elentns: FLICKITY CUSTOM DESIGNS --------------*/
.carousel-cell {
  padding: 0 10px !important;
}

.carousel-cell.none {
  padding: 0px !important;
}

.cols-6 .carousel-cell {
  width: 16.66%;
}

.cols-5 .carousel-cell {
  width: 20%;
}

.cols-4 .carousel-cell {
  width: 25%;
}

.cols-3 .carousel-cell {
  width: 33.3333%;
}

.flickity-page-dots .dot {
  background: var(--bs-primary);
}

.dots-full .flickity-page-dots .flickity-page-dot {
  height: 4px;
  width: 40px;
  margin: 0;
  border-radius: 0;
}

.dots-full .flickity-page-dots .flickity-page-dot:first-child {
  border-radius: 50px 0px 0px 50px;
}

.dots-full .flickity-page-dots .flickity-page-dot:last-child {
  border-radius: 0px 50px 50px 0px;
}

.flickity-button:focus {
  outline: 0;
  box-shadow: none !important;
}

.flickity-prev-next-button {
  top: 50%;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  transform: translateY(-50%);
}

.flickity-button {
  position: absolute;
  background: var(--bs-primary-bg-subtle);
  border: none;
  color: #333;
}

.flickity-button {
  color: var(--bs-primary);
}

.flickity-button:hover {
  background: var(--bs-primary);
  cursor: pointer;
  color: var(--bs-white);
}

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 28%;
  top: 28%;
  width: 44%;
  height: 44%;
}

.flickity-button:disabled {
  pointer-events: all;
  z-index: 2;
}

/*--------------------------------------------------
Navbar & Header Styles
---------------------------------------------------*/
.nav-brand,
.nav-brand:focus,
.nav-brand:hover,
.nav-menu > li > a {
  color: #022f5d;
}

.nav-dropdown.xxl-menu {
  min-width: 300px;
  max-width: 300px;
  right: auto !important;
}
.nav-dropdown.xxl-menu li {
  width: 100%;
  flex: 100%;
  clear: initial;
}
.nav-dropdown.xxl-menu li a {
  border: none;
}
.nav-dropdown.xxl-menu li a:hover {
  padding-left: 10px;
  border-radius: 1em;
  box-shadow: 0 0.5rem 0.5rem rgba(24, 142, 241, 0.1) !important;
  -webkit-box-shadow: 0 0.5rem 0.5rem rgba(24, 142, 241, 0.1) !important;
}
.nav-dropdown.xxl-menu li a:focus {
  padding-left: 10px;
  border-radius: 1em;
  box-shadow: 0 0.5rem 0.5rem rgba(24, 142, 241, 0.1) !important;
  -webkit-box-shadow: 0 0.5rem 0.5rem rgba(24, 142, 241, 0.1) !important;
}

.mega-advance-menu {
  display: flex;
  align-items: center;
  justify-content: start;
}
.mega-advance-menu .mega-last h6 {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px !important;
}

.nav-dropdown,
.nav-menu,
ul {
  list-style: none;
}

.navigation,
.navigation * {
  -webkit-tap-highlight-color: transparent;
}

.navigation,
.navigation * {
  box-sizing: border-box;
}

.navigation {
  width: 100%;
  display: table;
  position: relative;
  font-family: inherit;
}

.nav-toggle,
.navigation-hidden .nav-header {
  display: none;
}

.navigation-portrait {
  height: 48px;
}

.navigation-fixed {
  position: fixed;
  top: 0;
  left: 0;
}

.navigation-hidden {
  width: 0 !important;
  height: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
}

.align-to-right {
  float: right;
}

.nav-header {
  float: left;
}

.nav-brand {
  padding: 0px 15px 0px 0;
  font-size: 24px;
  margin-right: 1rem;
  margin-top: 2px;
  text-decoration: none !important;
}

.nav-dropdown > li > a,
.nav-menu > li > a,
nav a {
  text-decoration: none;
}

.navigation-portrait .nav-brand {
  font-size: 18px;
}

.nav-logo > img {
  height: 48px;
  margin: 11px auto;
  padding: 0 15px;
  float: left;
}

.nav-logo:focus > img {
  outline: initial;
}

nav a,
nav a:focus,
nav a:hover {
  outline: 0;
}

.navigation-portrait .nav-logo > img {
  height: 36px;
  margin: 6px auto 6px 15px;
  padding: 0;
}

.nav-toggle {
  width: 30px;
  height: 30px;
  padding: 6px 2px 0;
  position: absolute;
  top: 52%;
  margin-top: -14px;
  left: 0px;
  cursor: pointer;
}

.nav-toggle:before {
  content: "";
  position: absolute;
  width: 24px;
  height: 2px;
  background-color: #70798b;
  border-radius: 10px;
  box-shadow: 0 0.5em 0 0 #70798b, 0 1em 0 0 #70798b;
}

.navigation-portrait .nav-toggle {
  display: block;
}

.navigation-portrait .nav-menus-wrapper {
  width: 320px;
  height: 100%;
  top: 0;
  left: -400px;
  position: fixed;
  background-color: #fff;
  z-index: 20000;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  transition-duration: 0.8s;
  transition-timing-function: ease;
}

.navigation-portrait .nav-menus-wrapper.nav-menus-wrapper-right {
  left: auto;
  right: -400px;
}

.nav-menus-wrapper.nav-menus-wrapper-open {
  left: 0;
}

.nav-menus-wrapper.nav-menus-wrapper-right.nav-menus-wrapper-open {
  right: 0;
}

.nav-menus-wrapper-close-button {
  width: 35px;
  height: 35px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 10px 7px;
  display: none;
  float: right;
  color: var(--bs-primary);
  background-color: var(--bs-primary-bg-subtle);
  font-size: 16px;
  cursor: pointer;
}

.navigation-portrait .nav-menus-wrapper-close-button {
  display: flex;
}

.nav-menu {
  margin: 0;
  padding: 0;
  line-height: normal;
}

.navigation-portrait .nav-menu {
  width: 100%;
}

.navigation-landscape .nav-menu.nav-menu-centered {
  float: none;
  text-align: center;
}

.nav-menu > li {
  float: left;
  text-align: left;
}

.navigation-portrait .nav-menu > li {
  width: 100%;
  position: relative;
  border-top: solid 1px #f0f0f0;
}

.navigation-portrait .nav-menu > li:last-child {
  border-bottom: solid 1px #f0f0f0;
}

.nav-menu + .nav-menu > li:first-child {
  border-top: none;
}

.navigation-landscape .nav-menu.nav-menu-centered > li {
  float: none;
}

.nav-menu > li > a {
  padding: 14px 12px;
  display: inline-block;
  transition: color 0.3s, background 0.3s;
  font-weight: 400;
  letter-spacing: 0.2px;
  text-transform: capitalize;
  font-size: 15px;
}

.nav-menu.nav-menu-social li a {
  color: #0f393b;
}

.navigation-portrait .nav-menu > li > a {
  height: auto;
  width: 100%;
  padding: 12px 15px 12px 26px;
}

.nav-menu > .active > a,
.nav-menu > .focus > a,
.nav-menu > li:hover > a {
  color: var(--bs-primary) !important;
}

.navigation-portrait .nav-menu.nav-menu-social {
  width: 100%;
  text-align: center;
}

.nav-menu.nav-menu-social > li {
  text-align: center;
  border: none !important;
}

.navigation-portrait .nav-menu.nav-menu-social > li {
  width: auto;
}

.navigation-portrait .nav-menu.nav-menu-social > li > a {
  padding: 15px;
}

.submenu-indicator {
  margin-left: 6px;
  margin-top: 6px;
  float: right;
  transition: all 0.3s;
}

.navigation-portrait .submenu-indicator {
  width: 100%;
  height: 44px;
  margin-top: 0;
  position: absolute;
  text-align: center;
  z-index: 20000;
}

.submenu-indicator-chevron {
  height: 6px;
  width: 6px;
  display: block;
  border-style: solid;
  border-width: 0 1px 1px 0;
  border-color: transparent #39466d #39466d transparent;
  transform: rotate(45deg);
  transition: border 0.3s;
}

.navigation-portrait .submenu-indicator-chevron {
  position: absolute;
  top: 18px;
  right: 24px;
}

.nav-menu > .active > a .submenu-indicator-chevron,
.nav-menu > .focus > a .submenu-indicator-chevron,
.nav-menu > li:hover > a .submenu-indicator-chevron {
  border-color: transparent var(--bs-primary) var(--bs-primary) transparent;
}

.navigation-portrait .submenu-indicator.submenu-indicator-up {
  transform: rotate(0deg);
}

.navigation-portrait .submenu-indicator.submenu-indicator-up .submenu-indicator-chevron {
  transform: rotate(225deg);
}

.nav-overlay-panel {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  display: none;
  z-index: 19999;
}

.no-scroll {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.nav-text {
  margin: 25px 15px;
  color: #70798b;
}

.navigation-portrait .nav-text {
  width: calc(100% - 52px);
  margin: 12px 26px 0;
}

.navigation-portrait .nav-text + ul {
  margin-top: 15px;
}

.nav-dropdown {
  min-width: 250px;
  margin: 0;
  padding: 0;
  display: none;
  position: absolute;
  z-index: 98;
  white-space: nowrap;
}

.navigation-portrait .nav-dropdown {
  width: 100%;
  position: static;
  left: 0;
}

.nav-dropdown .nav-dropdown {
  left: 100%;
}

.nav-menu > li .nav-dropdown {
  border: none;
  padding: 15px;
  background: #ffffff;
  box-shadow: 0px 0px 2px 0px rgba(185, 199, 210, 0.45);
  -webkit-box-shadow: 0px 0px 2px 0px rgba(185, 199, 210, 0.45);
  border-radius: 0.4rem;
}

.nav-dropdown > li {
  width: 100%;
  float: left;
  clear: both;
  position: relative;
  text-align: left;
}

.nav-dropdown > li > a {
  width: 100%;
  padding: 14px 20px 14px 10px;
  border-bottom: 1px solid #e4e8ec;
  float: left;
  color: #11293b;
  transition: all 0.3s ease 0s;
  font-size: 13px;
  font-weight: 500;
}

.nav-dropdown > li > a .new-update {
  position: relative;
  font-size: 10px;
  font-weight: 500;
  background: #ff5722;
  color: #ffffff;
  padding: 2px 10px;
  border-radius: 50px;
  margin-left: 0.5rem;
}

.nav-dropdown > li:last-child > a {
  border-bottom: none;
}

.nav-dropdown > li > a:hover,
.nav-dropdown > li > a:focus {
  padding-left: 20px;
}

.social-icon a i,
.social-icons a i {
  width: 32px;
  height: 32px;
  display: inline-block;
  border-radius: 16px;
}

.nav-dropdown > .focus > a,
.nav-dropdown.nav-dropdown-left {
  right: 0;
}

.nav-dropdown > li > .nav-dropdown-left {
  left: auto;
  right: 100%;
}

.navigation-landscape .nav-dropdown-left > li > a {
  text-align: right;
}

.navigation-portrait .nav-dropdown > li > a {
  padding: 12px 20px 12px 30px;
}

.navigation-portrait .nav-dropdown > li > ul > li > a {
  padding-left: 50px;
}

.navigation-portrait .nav-dropdown > li > ul > li > ul > li > a {
  padding-left: 70px;
}

.navigation-portrait .nav-dropdown > li > ul > li > ul > li > ul > li > a {
  padding-left: 90px;
}

.navigation-portrait .nav-dropdown > li > ul > li > ul > li > ul > li > ul > li > a {
  padding-left: 110px;
}

.nav-dropdown .submenu-indicator {
  right: 15px;
  top: 16px;
  position: absolute;
}

.menu__list,
.navbar,
nav a {
  position: relative;
}

.navigation-portrait .submenu-indicator {
  right: 0;
  top: 0;
}

.nav-dropdown .submenu-indicator .submenu-indicator-chevron {
  transform: rotate(-45deg);
}

.navigation-portrait .nav-dropdown .submenu-indicator .submenu-indicator-chevron {
  transform: rotate(45deg);
}

.navigation-portrait .nav-dropdown .submenu-indicator.submenu-indicator-up .submenu-indicator-chevron {
  transform: rotate(225deg);
}

.nav-dropdown > .focus > a .submenu-indicator-chevron,
.nav-dropdown > li:hover > a .submenu-indicator-chevron {
  border-color: transparent var(--bs-primary) var(--bs-primary) transparent;
}

.navigation-landscape .nav-dropdown-left .submenu-indicator {
  left: 10px;
}

.navigation-landscape .nav-dropdown-left .submenu-indicator .submenu-indicator-chevron {
  transform: rotate(135deg);
}

nav a {
  display: inline-block;
}

.header {
  background: #fff;
  position: relative;
  padding: 13px 0;
}

.social-icons {
  float: right;
  margin-top: 0.7em;
}

.logo h1 a {
  color: #fff;
  font-size: 1.3em;
}

.logo span {
  display: block;
  font-size: 0.32em;
  letter-spacing: 4px;
}

.navbar-nav {
  float: left;
  margin: 0;
}

.nav-menu.nav-menu-social > li.list-buttons a {
  border-radius: 4px;
  position: relative;
  background: var(--bs-primary);
}

.nav-menu.nav-menu-social > li.list-buttons.light a {
  background: #ffffff;
}

.header.header-theme .nav-menu.nav-menu-social > li.list-buttons.light:hover > a {
  color: #0f393b !important;
}

.nav-menu.nav-menu-social > li.list-buttons.border a {
  background: #ffffff;
}

.header-fixed .nav-menu.nav-menu-social > li.list-buttons.light a {
  background: #ffffff;
}

.header-transparent.dark.header-fixed .nav-menu.nav-menu-social > li.list-buttons.light a {
  background: var(--bs-primary);
  color: #ffffff !important;
}

.nav-menu.nav-menu-social > li.list-buttons a {
  top: 0px;
  padding: 14px 14px;
  color: #ffffff !important;
}

.nav-menu.nav-menu-social > li.list-buttons.light a {
  color: #031925 !important;
}

.nav-menu.nav-menu-social > li.list-buttons.border a {
  color: var(--bs-primary) !important;
}

.header-fixed .nav-menu.nav-menu-social > li.list-buttons.light a {
  color: #041b2d !important;
}

.nav-menu.nav-menu-social > li.list-buttons.dark {
  background: #041b2d;
}

.nav-menu.nav-menu-social > li.list-buttons.dark a {
  color: #ffffff;
}

.header-fixed .nav-menu.nav-menu-social .text-light {
  color: #1f2e34 !important;
}

.nav-brand img {
  width: 190px;
  position: relative;
}

.nav-menu.nav-menu-social > li.list-buttons > a {
  display: inline-flex;
  align-items: center;
}

.navigation-portrait .nav-dropdown.xxl-menu {
  min-width: auto;
  max-width: 100%;
}

.navigation-portrait .nav-dropdown.xxl-menu > li {
  width: 100%;
  flex: 0 0 100%;
}

.currencyDropdown .nav-link,
.languageDropdown .nav-link,
.notificationsDropdown .nav-link {
  background: #ebeff2;
  border-radius: 0.32rem;
  min-width: 50px;
}

a.selectCurrency {
  border: none;
  padding: 0.8rem 1.2rem;
  border-radius: 0.32rem;
  text-align: left;
  background: #fff;
  transition: all ease 0.4s;
  width: 100%;
  display: flex;
  flex-direction: column;
}

a.selectCurrency:hover,
a.selectCurrency:focus {
  background: #f4f5f7;
  cursor: pointer;
}

a.selectCurrency.active {
  background: rgba(76, 175, 80, 0.11);
}

a.selectCurrency.active > div:last-child {
  color: #4caf50 !important;
}

a.selectCountry {
  border: none;
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 0.8rem 1.2rem;
  border-radius: 0.32rem;
  text-align: left;
  background: #ffffff;
  transition: all ease 0.4s;
  width: 100%;
}

a.selectCountry:hover,
a.selectCountry:focus {
  background: #f4f5f7;
  cursor: pointer;
}

a.selectCountry.active {
  background: rgba(76, 175, 80, 0.11);
}

a.selectCountry.active > div:last-child {
  color: var(--bs-primary) !important;
}

.devider-wraps {
  height: 0.5px;
  width: 100%;
  border: 1px dashed #e0e8ed;
  border-radius: 50px;
}

.devider-text {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  padding: 0 10px;
}

/*--------- Dashboard Dropdown ----------*/
.nav-menu.nav-menu-social > li:last-child .btn-group.account-drop {
  padding-right: 0;
}

.btn-group.account-drop {
  position: relative;
}
.btn-group.account-drop .btn.btn-order-by-filt {
  padding: 0px;
  height: 46px;
  width: 46px;
  color: #06325d;
  font-size: 18px;
  margin-top: 0;
  font-weight: 600;
  overflow: hidden;
  border-radius: 0.32rem !important;
}
.btn-group.account-drop .btn.btn-order-by-filt img.avater-img {
  width: 30px;
  border-radius: 50%;
}
.btn-group.account-drop .dropdown-menu {
  top: 70px !important;
  right: 0 !important;
  background: #fff;
  box-shadow: none;
  min-width: 300px;
  left: initial !important;
  border: none;
  padding: 0rem;
  overflow: hidden;
  z-index: 99;
  border-radius: 0.4rem;
  box-shadow: 0 0 20px 0 rgba(62, 28, 131, 0.1);
  -webkit-box-shadow: 0 0 20px 0 rgba(62, 28, 131, 0.1);
}
.btn-group.account-drop .dropdown-menu .drp_menu_headr {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.2rem;
  background: var(--bs-primary);
}
.btn-group.account-drop .dropdown-menu .drp_menu_headr h4 {
  font-size: 18px;
  margin: 0;
  color: #ffffff;
}
.btn-group.account-drop .dropdown-menu ul {
  padding: 0;
  margin: 0;
}
.btn-group.account-drop .dropdown-menu ul li {
  list-style: none;
  padding: 0;
  width: 100%;
  display: block;
}
.btn-group.account-drop .dropdown-menu ul li a {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: #06325d;
  position: relative;
  padding: 1rem 1.2rem;
  border-bottom: 1px solid #edf0f3;
}
.btn-group.account-drop .dropdown-menu ul li a i {
  margin-right: 7px;
  position: relative;
  top: 0px;
}
.btn-group.account-drop .dropdown-menu ul li a .notti_coun {
  position: absolute;
  right: 10px;
  width: 18px;
  height: 18px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #333333;
  border-radius: 50%;
  font-size: 13px;
  font-weight: 600 !important;
  color: #ffffff;
}
.btn-group.account-drop .dropdown-menu ul li a span.notti_coun.style-1 {
  background: #2bb170;
}
.btn-group.account-drop .dropdown-menu ul li a span.notti_coun.style-2 {
  background: #9972e6;
}
.btn-group.account-drop .dropdown-menu ul li a span.notti_coun.style-3 {
  background: #03a9f4;
}

/*--------- Mobile Nav ----------*/
.mobile_nav ul {
  padding: 0;
  margin: 0;
  display: inline-flex;
}
.mobile_nav ul li {
  list-style: none;
  margin-left: 0rem;
  display: inline-flex;
}
.mobile_nav ul li a {
  height: 50px;
  width: 50px;
  font-weight: 500;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.mobile_nav ul .dhsbrd li {
  margin: 0;
  display: inline-block;
  width: auto !important;
}
.mobile_nav ul .dhsbrd li a {
  border: none !important;
  width: auto;
  display: inline-flex;
}
.mobile_nav ul .dhsbrd li .account-drop ul li a {
  width: 100%;
}

.user-Dashboard-menu {
  padding: 0;
  margin: 0;
  padding: 0.6rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.user-Dashboard-menu li {
  display: inline-flex;
  width: auto;
  list-style: none;
}
.user-Dashboard-menu li a {
  font-weight: 500;
  font-size: 14px;
  color: #96a2b0;
  padding: 0.6rem 0;
}
.user-Dashboard-menu li a:hover {
  color: var(--bs-primary);
}
.user-Dashboard-menu li a:focus {
  color: var(--bs-primary);
}
.user-Dashboard-menu li a:active {
  color: var(--bs-primary);
}

.user-Dashboard-longmenu {
  padding: 0;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.user-Dashboard-longmenu li {
  display: inline-flex;
  width: 100%;
  padding: 0 0.7rem;
  list-style: none;
}
.user-Dashboard-longmenu li:nth-child(even) {
  background: #f1f5f8;
}
.user-Dashboard-longmenu li a {
  font-weight: 500;
  font-size: 14px;
  color: #838f9d;
  width: 100%;
  padding: 0.8rem 0;
}
.user-Dashboard-longmenu li a:hover {
  color: var(--bs-primary);
}
.user-Dashboard-longmenu li a:focus {
  color: var(--bs-primary);
}
.user-Dashboard-longmenu li a:ative {
  color: var(--bs-primary);
}

.brd img {
  border: 4px solid #b77a7a;
}

/*--------------------------------------------------
Intro Banner & Hero Header
---------------------------------------------------*/
.image-bg {
  background-size: cover !important;
  background-position: center !important;
}

.image-cover {
  background-size: cover !important;
  background-position: center !important;
}

.image-bottom-cover {
  background-size: cover !important;
  background-position: bottom !important;
}

.image-bottom {
  background-size: auto !important;
  background-position: bottom !important;
}

.image-cover .container,
.bg-cover .container {
  position: relative;
  z-index: 1;
}

.hero-header {
  padding: 5em 0 5em;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  min-height: 650px;
}

.fill-primary {
  fill: var(--bs-primary) !important;
}

.fill-white {
  fill: #ffffff !important;
}

.fill-light {
  fill: #f4f5f7 !important;
}

.avatar-group {
  padding: 0;
  display: flex;
}

.avatar-group > li {
  position: relative;
}

.avatar-group > li:not(:last-child) {
  margin-right: -0.8rem;
}

.flightsLists-Wraps {
  margin: 0;
  width: 100%;
}

.flightsLists-Wraps li {
  padding: 8px 0;
  list-style: none;
  width: 100%;
}

.fpc-banner {
  position: absolute;
  bottom: -2px;
  left: 0;
  right: 0;
  display: inline-block;
  width: 100%;
  height: 0;
  z-index: 2;
  padding-top: 4.920635%;
  /* background-image: url(../img/mask-bg.png);  */ 
  background-repeat: no-repeat;
  background-size: cover;
}

.carousel-inner .carousel-item {
  padding: 6rem 0;
  min-height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel-caption {
  position: relative;
  right: auto !important;
  bottom: 0;
  left: auto !important;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-control-prev {
  left: 3%;
}

.carousel-control-next {
  right: 3%;
}

.carousel-control-next,
.carousel-control-prev {
  width: 50px;
  top: 50%;
  transform: translateY(-50%);
  bottom: auto;
  opacity: 1;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background: #ffffff;
  position: relative;
  background-image: none !important;
}

.carousel-control-next-icon:before {
  content: "\f178";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  color: #061e2d;
  opacity: 1;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  font-size: 12px;
  transform: translate(-50%, -50%);
}

.carousel-control-prev-icon:before {
  content: "\f177";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  color: #061e2d;
  opacity: 1;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  font-size: 12px;
  transform: translate(-50%, -50%);
}

.search-wrap.overio {
  top: -40px;
  position: relative;
  z-index: 1;
  transform: translateY(-40px);
}

/*--------------------------------------------------
GeoTrip Elements Styles
---------------------------------------------------*/
/*---------- GeoTrip Elements Styles: Tours and Place Styles ------------*/
.pop-touritem {
  position: relative;
  height: 100% !important;
  display: flex;
  flex-wrap: wrap;
}

.fs-title {
  font-size: 1.12rem !important;
}

.detail.ellipsis-container {
  color: #455873;
  font-size: 14px;
  padding-top: 3px;
}

.ellipsis-item__normal.separate {
  display: inline-block;
  width: 1px;
  height: 10px;
  margin: 0 5px;
  background-color: #ced2d9;
}

.touritem-middle {
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.flight-foots {
  position: relative;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.low-price .tag-span {
  font-size: 13px;
  font-weight: 500;
  color: #5f7388;
}

.pop-touritem-bg {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  box-shadow: 0 8px 16px 0 rgba(15, 41, 77, 0.08);
  overflow: hidden;
  cursor: pointer;
  position: relative;
}

.pop-touritem-bg .bg-cover {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  transition: 0.2s;
  background-position-y: -10px;
}

.pop-touritem-bg .touritem-cnt {
  position: absolute;
  bottom: 0;
  z-index: 1;
  padding: 0 24px 16px;
}

.detail.ellipsis-container .ellipsis {
  margin: 0 5px 5px 0px;
  font-size: 13px;
  padding: 4px 10px;
  border-radius: 4px;
  display: inline-block;
  color: #45596c;
  border: 1px dashed #ccd5de;
}

.flight-footer {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.star-rates {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 11px;
  letter-spacing: 3px;
}

.star-rates .fa-solid.active {
  color: #ffa31a;
}

.rat-reviews {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 2px;
}

.rat-reviews span {
  margin-left: 3px;
  color: #455873;
  font-size: 12px;
  font-weight: 500;
}

.activities-flex {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #05264e;
}

.activities-flex li {
  list-style: none;
}

.activities-flex li .actv-wrap-caps {
  color: #808fa0;
  font-size: 13px;
}

.popFlights-item-overHidden {
  position: relative;
  overflow: hidden;
}

.flight-thumb-wrapper .popFlights-item-overHidden > img,
.flight-thumb-wrapper .popFlights-item-overHidden > img {
  transition: all ease 0.4s;
}

.flight-thumb-wrapper:hover .popFlights-item-overHidden > img,
.flight-thumb-wrapper:focus .popFlights-item-overHidden > img {
  transform: scale(1.05);
}

.segmentDeparture ul {
  padding: 0;
  margin: 0;
  position: relative;
  border-left: 1px solid #a6bbca;
}

.segmentDeparture ul li {
  margin-bottom: 0;
  padding-left: 1.2rem;
}

.segmentDeparture ul li:first-child {
  margin-bottom: 1.8rem;
}

.segmentDeparture ul:before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  background: var(--bs-primary);
  border: 4px solid var(--bs-primary-bg-subtle);
  border-radius: 50%;
  left: -10px;
  top: 0;
}

.segmentDeparture ul:after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  background: var(--bs-primary);
  border: 4px solid var(--bs-primary-bg-subtle);
  border-radius: 50%;
  left: -10px;
  bottom: 0;
}

.segmentDeparture-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.segmentDeparture-overlay {
  position: relative;
  border-left: 1px dashed #c4ced7;
  padding: 3rem;
}

/*---------- GeoTrip Elements Styles: Tours Lists Styles ------------*/
.main-carousel.list-layouts {
  height: 100%;
}

.main-carousel.list-layouts .carousel-cell {
  height: 100%;
  width: 100%;
}

.arrow-hide .flickity-page-dots {
  display: none;
}

.flickity-page-dot:focus {
  outline: 0;
  box-shadow: 0 0 0 5px var(--bs-primary-bg-subtle);
}

.flickity-page-dot {
  background: var(--bs-primary-bg-subtle);
}

.flickity-page-dot.is-selected {
  background: var(--bs-primary);
}

/*---------- GeoTrip Elements Styles: Detail Page Styles ------------*/
.galleryGrid {
  display: grid;
  grid-template-columns: 0.52fr 0.24fr 0.24fr;
  grid-template-rows: 1fr 1fr;
  gap: 10px;
}

.galleryGrid.typeGrid_1 > *:nth-child(1) {
  grid-row: 2 span;
}

.galleryGrid.typeGrid_2 > *:nth-child(1) {
  grid-row: 2 span;
}

.galleryGrid.typeGrid_2 > *:nth-child(2) {
  grid-column: 2 span;
}

.galleryGrid.typeGrid_3 {
  grid-template-columns: 0.52fr 0.24fr 0.24fr 0.24fr 0.24fr;
}

.galleryGrid.typeGrid_3 > *:nth-child(1) {
  grid-row: 2 span;
}

.galleryGrid.typeGrid_3 > *:nth-child(6),
.galleryGrid.typeGrid_3 > *:nth-child(7) {
  grid-column: 2 span;
}

.galleryGrid.typeGrid_1 img,
.galleryGrid.typeGrid_2 img,
.galleryGrid.typeGrid_3 img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.couponSep {
  border-bottom: 1px solid #e3e9ed;
  position: relative;
  margin: 30px 0;
}

.couponSepText {
  width: 28px;
  height: 28px;
  background: #f1f6f9;
  font-size: 11px;
  font-weight: 600;
  border: 1px solid #f1f6f9;
  position: absolute;
  top: -13px;
  border-radius: 30px;
  left: 45%;
  padding: 4px 4px;
  color: #bcc9d1;
}

/*---------- GeoTrip Elements Styles: Flights List Styles ------------*/
.flightLine {
  position: relative;
  width: 100%;
  height: 0;
  top: 10px;
  border: 1px dashed #dee2e6;
}

.flightLine > * {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  border: 1px solid #dee2e6;
  border-radius: 100%;
}

.flightLine > *:nth-child(1) {
  left: -2px;
  background-color: #ffffff;
}

.flightLine > *:nth-child(2) {
  right: -2px;
  background-color: #dee2e6;
}

.flightLine.departure:before {
  content: "\f0fb";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  color: #a0abb8;
  position: absolute;
}

.flightLine.return:before {
  content: "\e518";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  left: 50%;
  transform: translate(65%, -50%);
  top: 65%;
  position: absolute;
  rotate: 275deg;
  color: #a0abb8;
}

/*---------- GeoTrip Elements Styles: City Styles ------------*/
.cardCities {
  position: relative;
  z-index: 0;
}

.cardCities.cursor:hover {
  cursor: pointer;
}

.cardCities .cardCities-image {
  border-radius: inherit;
}

.ratio {
  position: relative;
  display: block;
  overflow: hidden;
}

.ratio-4::before {
  padding-bottom: 125%;
}

.ratio::before {
  display: block;
  width: 100%;
  content: "";
}

.ratio-full::before {
  padding-bottom: 100%;
}

.cardCities .cardCities-image {
  border-radius: inherit;
}
.cardCities .cardCities-image img {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
}

.citiesCard-content {
  overflow: hidden;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
}
.citiesCard-content .cardCities-bg {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(2, 7, 45, 0.44);
  border-radius: inherit;
  transition: all ease 0.5s;
  opacity: 0.3;
}

.citiesCard-topcaps {
  transition: all ease 0.5s;
  opacity: 0;
}

.citiesCard-bottomcaps {
  transition: all ease 0.5s;
  transform: translateY(80px);
}

.cardCities:hover .citiesCard-bottomcaps,
.cardCities:focus .citiesCard-bottomcaps {
  transform: translateY(0);
}

.cardCities:hover .cardCities-bg,
.cardCities:focus .cardCities-bg {
  opacity: 1;
}

.cardCities:hover .citiesCard-topcaps,
.cardCities:focus .citiesCard-topcaps {
  opacity: 1;
}

.destinations-pics a {
  position: relative;
  display: block;
}
.destinations-pics a:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: #031628;
  height: 100%;
  opacity: 0.4;
  z-index: 1;
  transition: all ease 0.4s;
}

.cardOffer-content {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
}
.cardOffer-content:before {
  content: "";
  border-radius: inherit;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(5, 5, 5, 0.7) 0%, rgba(5, 5, 5, 0) 100%);
}
.cardOffer-content .cpnCode {
  background: rgba(255, 255, 255, 0.2);
  border: 2px dashed rgba(255, 255, 255, 0.5);
}

.destinations-pics:hover a:before,
.destinations-pics:focus a:before {
  opacity: 0.4;
  z-index: 1;
  background: #031628;
}

.destinations-pics a > img {
  transition: all ease 0.4s;
}

.destinations-pics:hover a > img {
  transform: scale(1.2);
}

/*---------- GeoTrip Elements Styles: Offers Styles ------------*/
.cardOffer .cardOffer-image {
  border-radius: inherit;
}
.cardOffer .cardOffer-image img {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
}

.recommend-module-card {
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 8px 16px 0 rgba(15, 41, 77, 0.08);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-decoration: none;
  color: inherit;
  cursor: auto;
  position: relative;
  min-height: 320px;
  height: 100%;
  transform: translateZ(0);
}
.recommend-module-card .recommend-module-card.min-ht {
  height: auto;
  min-height: 450px;
}
.recommend-module-card .bokker-bg-full {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  transition: transform 0.2s ease-in-out;
  background-position: center !important;
  background-size: cover !important;
}
.recommend-module-card .bokker-bg-full:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #0e223c;
  display: block;
  opacity: 0.3;
}
.recommend-module-card .bokker-bg-full:hover {
  transform: scale(1.1);
}
.recommend-module-card .bokker-content {
  margin-top: auto;
  position: relative;
  z-index: 2;
  padding: 28px 16px 16px;
  color: #fff;
  text-align: left;
}
.recommend-module-card .bokker-content .bokker-content-text {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  word-break: break-word;
}
.recommend-module-card .bokker-content .bokker-content-button {
  margin-top: 16px;
  height: 44px;
  line-height: 44px;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: var(--bs-primary);
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  padding: 0 8px;
  transition: background-color 0.2s;
}
.recommend-module-card .bokker-content .bokker-content-button:hover {
  background-color: #c0241a;
}

/*----------------------------------------------
  Inner Page Design 
----------------------------------------------*/
.single-paymntCardsoption .paymnt-line {
  border: 2px solid #ecf1f6;
  border-radius: 0.375rem;
  padding: 0.5rem 0.4rem 0.5rem 0.4rem;
}

.single-paymntCardsoption .paymnt-line.active {
  border-color: var(--bs-primary-bg-subtle);
}

.bs-stepper-header {
  display: flex;
  align-items: center;
}

.bs-stepper .line,
.bs-stepper-line {
  flex: 1 0 32px;
  min-width: 1px;
  min-height: 1px;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.12);
}

.bs-stepper .step-trigger:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.bs-stepper .step-trigger {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5;
  color: #6c757d;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: none;
  padding-top: 20px;
  border-radius: 0.25rem;
  transition: background-color 0.15s ease-out, color 0.15s ease-out;
}

.bs-stepper-label {
  display: inline-block;
  margin: 0.25rem;
}

.bs-stepper-circle {
  display: inline-flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
  padding: 0.5em 0;
  margin: 0.25rem;
  color: var(--bs-primary);
  border: 4px solid #ffffff;
  background-color: var(--bs-primary-bg-subtle);
  border-radius: 50%;
}

.step.active .bs-stepper-circle {
  background: var(--bs-primary);
  color: #ffffff;
}

.step.completed .bs-stepper-circle {
  background: #4caf75;
  color: #ffffff;
}

/*-------------- Inner Page Design: Career Page Styles ---------------*/
a.careerBox {
  border-radius: 1rem;
  background: #f1f5f8;
  display: flex;
  flex-direction: column;
  padding: 2rem 1.5rem;
  transition: all ease 0.4s;
}

a.careerBox:hover,
a.careerBox:focus {
  background: var(--bs-primary);
}

a.careerBox:hover .icons,
a.careerBox:focus .icons,
a.careerBox:hover .career-tlt,
a.careerBox:focus .career-tlt,
a.careerBox:hover .career-jbs,
a.careerBox:focus .career-jbs {
  color: #ffffff;
}

a.careerBox:hover .careerBox-link,
a.careerBox:focus .careerBox-link {
  color: #ffc107;
}

.comp-property {
  position: relative;
  display: block;
  border: 2px solid #dce5eb;
  border-radius: 0.6rem;
  padding: 10px;
}
.comp-property span.remove-from-compare {
  position: absolute;
  width: 32px;
  height: 32px;
  right: 15px;
  top: 20px;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: var(--bs-primary);
}
.comp-property .clp-title {
  padding: 10px 0;
}
.comp-property .clp-title h4 {
  font-size: 16px;
  margin: 0;
}
.comp-property .clp-title span {
  font-size: 13px;
  font-weight: 600;
  color: var(--bs-primary);
}
.comp-property ul {
  padding: 0;
  margin: 0;
}
.comp-property ul li {
  font-size: 13px;
  font-weight: 500;
  list-style: none;
  padding: 12px 0;
  border-top: 1px solid #e6ebee;
}

.blockquote {
  position: relative;
  padding-left: 2.75rem;
}

/*--------------------------------------------------
Footer Style
---------------------------------------------------*/
footer {
  z-index: 99;
  font-weight: 500;
  position: relative;
  background: #ffffff;
}

.skin-theme-footer {
  background: var(--bs-primary);
}

.skin-dark-footer {
  background: #003149;
}

.footer-top {
  position: relative;
  display: block;
  padding: 1.5rem 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

.skin-light-footer .footer-top {
  border-bottom: 1px solid #eff4f9;
}

img.img-footer {
  max-width: 180px;
  margin-bottom: 1.5rem;
}

.footer-add {
  color: #b5c9dd;
  font-size: 13px;
}

.footer-logo img {
  max-width: 185px;
}

.footer-widget {
  padding: 70px 0;
}
.footer-widget h4.widget-title {
  margin-bottom: 20px;
  font-size: 15px;
  color: #003a75;
}
.footer-widget ul {
  padding: 0;
  margin: 0;
}
.footer-widget ul li {
  list-style: none;
  margin-top: 15px;
  display: block;
}
.footer-widget ul li a {
  color: #6e8497;
  font-size: 13px;
}
.footer-widget ul li a:hover {
  color: var(--bs-primary);
}
.footer-widget ul li a:focus {
  color: var(--bs-primary);
}
.footer-widget ul li a:hactive {
  color: var(--bs-primary);
}
.footer-widget ul li p {
  color: #6e8497;
  font-size: 13px;
}

.skin-theme-footer .footer-widget ul li a,
.skin-theme-footer .footer-widget p {
  color: rgba(255, 255, 255, 0.6);
}

footer.skin-dark-footer .footer-widget ul li a,
footer.skin-dark-footer,
footer.skin-dark-footer a,
.skin-dark-footer .footer-widget p {
  color: #557685;
  transition: all ease 0.4s;
  font-size: 13px;
}

footer.skin-dark-footer .footer-widget ul li a:hover,
footer.skin-dark-footer .footer-widget ul li a:focus {
  color: #ffffff;
}

.widget_media_image {
  margin-top: 3rem;
}

.footer-bottom {
  padding: 15px 0;
  color: #022f5d;
  font-size: 13px;
}

footer.skin-dark-footer h4,
footer.skin-dark-footer .h4 {
  color: #fff !important;
}

.skin-theme-footer .footer-bottom {
  color: #ffffff;
}

.skin-dark-footer .footer-bottom {
  color: #ffffff;
}

.footer-widget .ftp-info {
  background: #e2e7ef;
  display: flex;
  max-width: 225px;
  padding: 0.5rem 1rem;
  align-items: center;
  border-radius: 50px;
  font-size: 15px;
  font-weight: 600;
}
.footer-widget .ftp-info i {
  margin-right: 10px;
}

footer.skin-dark-footer .footer-widget .ftp-info {
  background: #003364;
  color: #ffffff;
}

.call-action-caption h2 {
  font-weight: 800;
}

.foot-socials {
  position: relative;
  display: block;
  margin-top: 1.5rem;
}
.foot-socials ul {
  margin: 0;
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
}
.foot-socials ul li {
  list-style: none;
  display: inline-block;
  position: relative;
  margin-right: 10px;
}
.foot-socials ul li a {
  width: 38px;
  height: 38px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.2rem;
  background: rgba(255, 255, 255, 0.1);
  font-size: 15px !important;
  transition: all ease 0.4s;
}
.foot-socials ul li a:hover {
  background: var(--bs-primary) !important;
  color: #ffffff;
}

.skin-light-footer .foot-socials ul li a {
  background: #edf3f7;
}

.job-info-count-group {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  justify-content: flex-end;
}

.single-jb-info-count {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-right: 2rem;
}
.single-jb-info-count .jbs-y7 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.single-jb-info-count .jbs-y7 .ctr {
  color: #ffffff;
  margin: 0;
  margin-right: 2px;
  font-size: 20px;
}
.single-jb-info-count .jbs-y7 span {
  font-size: 17px;
  font-weight: 600;
}

.single-jb-info-count:last-child {
  margin-right: 0;
}

.jbs-y5 p {
  margin: 0;
  color: #557685;
  font-weight: 500;
  font-size: 13px;
}

.skin-light-footer .jbs-y5 p {
  color: #6e8497;
}

.lg-ctr .single-jb-info-count .jbs-y7 .ctr {
  font-size: 35px;
  line-height: 1;
}

.lg-ctr .single-jb-info-count .jbs-y7 span {
  font-size: 30px;
}

.lg-ctr .jbs-y5 p {
  font-size: 14px;
}

.skin-theme-footer .lg-ctr .jbs-y5 p {
  color: rgba(255, 255, 255, 0.6);
}

.skin-dark-footer .footer-bottom.border-top {
  border-color: #0f4863 !important;
}

a#back2Top {
  position: fixed;
  right: 12px;
  bottom: 10px;
  padding: 7px 13px;
  height: auto;
  border-radius: 4px;
  background: var(--bs-primary-bg-subtle);
  font-size: 18px;
  color: var(--bs-primary);
}

/*--------------------------------------------------
Responsiveness Start
---------------------------------------------------*/
@media all and (min-height: 600px) and (min-width: 767px) {
  .height-10 {
    height: 10vh;
  }
  .height-20 {
    height: 20vh;
  }
  .height-30 {
    height: 30vh;
  }
  .height-40 {
    height: 40vh;
  }
  .height-50 {
    height: 50vh;
  }
  .height-60 {
    height: 60vh;
  }
  .height-70 {
    height: 70vh;
  }
  .height-80 {
    height: 80vh;
  }
  .height-90 {
    height: 90vh;
  }
  .height-100 {
    height: 100vh;
  }
}
@media (min-width: 993px) {
  .head-shadow {
    box-shadow: 0 5px 30px rgba(0, 22, 84, 0.1);
    -webkit-box-shadow: 0 5px 30px rgba(0, 22, 84, 0.1);
  }
  .header.head-border {
    border-bottom: 1px solid #e9ecef;
  }
  .hero-header {
    min-height: 650px;
    padding: 7rem 0;
  }
  div#MobNav {
    display: block;
  }
  a.mobNavigation {
    display: none;
  }
  .header.header-fixed {
    border-bottom: 1px solid #e9ecef;
    background: #ffffff;
    position: fixed;
    z-index: 999;
    width: 100%;
    top: 0;
    transition: 0.2s ease-in;
    animation-duration: 0.5s;
    animation-name: slideInDown;
  }
  .header.header-light.head-fixed {
    position: fixed;
    z-index: 99;
    width: 100%;
    transition: none !important;
    border-bottom: 1px solid #e6ecf0;
    animation-name: none !important;
  }
  .filter_search_opt {
    display: none;
  }
  .header.header-transparent {
    background: transparent;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: inline-block;
    width: 100%;
    z-index: 4;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .change-logo .nav-brand.fixed-logo {
    display: none;
  }
  .change-logo.header-fixed .nav-brand.fixed-logo {
    display: block;
  }
  .change-logo.header-fixed .nav-brand.static-logo {
    display: none;
  }
  .header.header-transparent.header-fixed {
    border-bottom: 1px solid #e9ecef;
    background: #ffffff;
    position: fixed;
    z-index: 999;
    width: 100%;
    top: 0;
    transition: 0.2s ease-in;
    animation-duration: 0.5s;
    animation-name: slideInDown;
  }
  .header .nav-menu > .active > a .submenu-indicator-chevron,
  .header .nav-menu > .focus > a .submenu-indicator-chevron,
  .header .nav-menu > li:hover > a .submenu-indicator-chevron {
    border-color: transparent var(--bs-primary) var(--bs-primary) transparent;
  }
  /*------------ Header Theme Style -------------*/
  .header.header-transparent.theme.header-fixed {
    background: var(--bs-primary);
    border-color: var(--bs-primary);
  }
  .header.header-theme {
    background: var(--bs-primary);
    border-color: var(--bs-primary);
  }
  .header.header-dark {
    background: #041625;
    border-color: #041625;
  }
  .header.header-transparent.dark.header-fixed {
    background: #041b2d;
    border-color: #041b2d;
  }
  .header.header-transparent .nav-brand,
  .header.header-transparent .nav-brand:focus,
  .header.header-transparent .nav-brand:hover,
  .header.header-transparent .nav-menu > li > a {
    color: #ffffff;
  }
  .header.header-transparent .submenu-indicator-chevron {
    border-color: transparent #ffffff #ffffff transparent;
  }
  .header.header-transparent .nav-submenu .submenu-indicator-chevron,
  .header.header-transparent.header-fixed .nav-submenu .submenu-indicator-chevron {
    border-color: transparent #70798b #70798b transparent;
  }
  .header.header-theme .nav-submenu .submenu-indicator-chevron,
  .header.header-theme.header-fixed .nav-submenu .submenu-indicator-chevron {
    border-color: transparent #70798b #70798b transparent;
  }
  .header.header-transparent.header-fixed .nav-brand,
  .header.header-transparent.header-fixed .nav-brand:focus,
  .header.header-transparent.header-fixed .nav-brand:hover,
  .header.header-transparent.header-fixed .nav-menu > li > a {
    color: #ffffff;
  }
  .header.header-transparent.header-fixed .submenu-indicator-chevron {
    border-color: transparent #ffffff #ffffff transparent;
  }
  .header.header-theme .nav-brand,
  .header.header-theme .nav-brand:focus,
  .header.header-theme .nav-brand:hover,
  .header.header-theme .nav-menu > li > a {
    color: #ffffff;
  }
  .header.header-dark .nav-brand,
  .header.header-dark .nav-brand:focus,
  .header.header-dark .nav-brand:hover,
  .header.header-dark .nav-menu > li > a {
    color: #ffffff;
  }
  .submenu-indicator-chevron {
    border-color: transparent #39466d #39466d transparent;
  }
  .header.header-theme .submenu-indicator-chevron {
    border-color: transparent #ffffff #ffffff transparent;
  }
  .header.header-theme .nav-menu > .active > a,
  .header.header-theme .nav-menu > .focus > a,
  .header.header-theme .nav-menu > li:hover > a,
  .header.header-transparent.theme.header-fixed .nav-menu > .active > a,
  .header.header-transparent.theme.header-fixed .nav-menu > .focus > a,
  .header.header-transparent.theme.header-fixed .nav-menu > li:hover > a,
  .header.header-transparent.dark.header-fixed .nav-menu > .active > a,
  .header.header-transparent.dark.header-fixed .nav-menu > .focus > a,
  .header.header-transparent.dark.header-fixed .nav-menu > li:hover > a {
    color: #ffffff !important;
  }
  .header.header-theme .nav-menu > .active > a .submenu-indicator-chevron,
  .header.header-theme .nav-menu > .focus > a .submenu-indicator-chevron,
  .header.header-theme .nav-menu > li:hover > a .submenu-indicator-chevron,
  .header.header-transparent.theme .nav-menu > .active > a .submenu-indicator-chevron,
  .header.header-transparent.theme .nav-menu > .focus > a .submenu-indicator-chevron,
  .header.header-transparent.theme .nav-menu > li:hover > a .submenu-indicator-chevron,
  .header.header-transparent.dark .nav-menu > .active > a .submenu-indicator-chevron,
  .header.header-transparent.dark .nav-menu > .focus > a .submenu-indicator-chevron,
  .header.header-transparent.dark .nav-menu > li:hover > a .submenu-indicator-chevron {
    border-color: transparent #ffffff #ffffff transparent;
  }
  .header.header-transparent.theme.header-fixed .nav-menu.nav-menu-social > li:hover a,
  .header.header-transparent.theme.header-fixed .nav-menu.nav-menu-social > li:focus a {
    color: #031925 !important;
  }
  .nav-submenu .submenu-indicator-chevron {
    border-color: transparent #70798b #70798b transparent;
  }
  .sec-heading h2 {
    font-size: 35px;
    line-height: 1.4;
    font-weight: 800;
  }
  .header.header-transparent .currencyDropdown .nav-link,
  .header.header-transparent .languageDropdown .nav-link {
    background: rgba(255, 255, 255, 0.2);
    color: #ffffff !important;
  }
  .header.header-theme .currencyDropdown .nav-link,
  .header.header-theme .languageDropdown .nav-link {
    background: rgba(255, 255, 255, 0.2);
    color: #ffffff !important;
  }
  .header.header-dark .currencyDropdown .nav-link,
  .header.header-dark .languageDropdown .nav-link {
    background: rgba(255, 255, 255, 0.2);
    color: #ffffff !important;
  }
  .mobile_nav {
    display: none;
  }
  .mob-show {
    display: none;
  }
}
@media (min-width: 1024px) {
  .hero-header h1 {
    font-size: 2.8em;
    line-height: 1.31818182em;
  }
}
@media (min-width: 1200px) {
  .carousel-inner .carousel-item {
    padding: 6rem 0;
    min-height: 750px;
  }
  .hero-header h1 {
    font-size: 3em;
    line-height: 1.31818182em;
  }
  .h1,
  h1 {
    font-size: 2.5rem;
  }
  .h2,
  h2 {
    font-size: 2rem;
  }
}
@media (min-width: 1500px) {
  .hero-header h1 {
    font-size: 4em;
    line-height: 1.31818182em;
  }
  .carousel-inner .carousel-item {
    padding: 6rem 0;
    height: 100vh;
    min-height: 650px;
  }
  .hero-header {
    min-height: 750px;
    height: 100vh;
  }
  .xl-heading {
    font-size: 4em;
    line-height: 1.31818182em;
  }
  .h1,
  h1 {
    font-size: 3.5rem;
  }
  .h2,
  h2 {
    font-size: 2.5rem;
  }
}
@media (max-width: 1200px) {
  .cols-4 .carousel-cell {
    width: 33.333333%;
  }
}
@media (max-width: 992px) {
  .nav-menu.nav-menu-social {
    display: none;
  }
  .static-show {
    display: none;
  }
  .account-drop .dropdown-menu ul li {
    margin-left: 0;
  }
  .nav-header {
    display: flex;
    align-items: center;
    float: left;
    width: 100%;
  }
  .navigation-portrait .nav-brand {
    margin-left: 35px;
    flex: 1;
    margin-top: 0;
  }
  .nav-menu > li .nav-dropdown {
    box-shadow: none;
    padding: 0;
  }
  .nav-menu > li > .nav-dropdown:before {
    display: none;
  }
  .footer-widget {
    padding-bottom: 60px;
    padding-top: 0;
  }
  footer.dark-footer,
  footer.skin-light-footer,
  footer.skin-dark-footer {
    padding-top: 60px;
  }
  ul.mobile_nav.dhsbrd {
    margin: 0;
  }
  .w-50 {
    width: 100% !important;
  }
  .cols-3 .carousel-cell {
    width: 50%;
  }
  .cols-4 .carousel-cell {
    width: 50%;
  }
}
@media (max-width: 991px) {
  a.nav-brand.static-logo {
    display: none;
  }
}
@media (max-width: 767px) {
  h1 {
    font-size: 35px;
  }
  h2 {
    line-height: 28px;
    font-size: 22px;
  }
  .fs-mob {
    font-size: 1rem !important;
  }
  .h-mob-auto {
    height: auto !important;
  }
  .footer-bottom .job-info-count-group {
    justify-content: flex-start;
    margin-top: 1rem;
  }
  .mob-full {
    width: 100%;
  }
  .cols-3 .carousel-cell {
    width: 100%;
  }
  .cols-4 .carousel-cell {
    width: 50%;
  }
  #currencyModal .modal-body,
  #countryModal .modal-body {
    padding: 1rem 0.4rem !important;
  }
  a.selectCurrency,
  a.selectCountry {
    padding: 0.8rem 0.6rem;
  }
  .devider-text {
    font-size: 12px;
  }
  #loginmodal .social-login .row .col {
    padding: 0 2px;
  }
  .search-wrap .fliore .nav-tabs.medium .nav-link {
    padding: 0.8rem 0.8rem;
  }
  .card-header.nds-block {
    display: block !important;
  }
  .galleryGrid.typeGrid_3 {
    grid-row: 2 span;
  }
  .galleryGrid.typeGrid_3 {
    grid-column: 2 span;
  }
  .galleryGrid.typeGrid_3 {
    grid-template-columns: 1fr 1fr;
  }
  .bs-stepper .step-trigger {
    padding-top: 0px;
  }
  .flickity-enabled.is-draggable .flickity-viewport {
    min-height: 280px;
    width: 100%;
  }
  .carousel-cell img.object-fit {
    width: 100%;
  }
  .galleryGrid__item .btn {
    font-size: 11px;
    padding: 5px 12px;
    font-weight: 600 !important;
  }
  .flickity-prev-next-button.next {
    right: 15px;
  }
  .flickity-prev-next-button.previous {
    left: 15px;
  }
}
@media (max-width: 600px) {
  .cols-4 .carousel-cell {
    width: 100%;
  }
  .search-wrap .nav-pills.medium .nav-link,
  .nav-pills.medium .nav-link {
    padding: 0.6rem 0.8rem;
    margin-right: 6px;
    font-size: 10px;
    font-weight: 600;
  }
  .search-wrap .nav-pills.medium .nav-link i,
  .nav-pills.medium .nav-link i {
    margin-right: 0.5rem !important;
  }
  .nav-tabs.medium .nav-link {
    padding: 0.6rem 1rem;
    font-size: 13px;
    font-weight: 500;
  }
}
@media (max-width: 575px) {
  .nav-brand {
    padding-right: 0px;
  }
  .btn-flip-icon {
    position: absolute;
    left: 50%;
    top: 100%;
    transform: translate(-50%, -50%);
    margin-top: 0.5rem;
  }
  .nav-brand img {
    max-width: 115px;
    position: relative;
  }
  footer .position-absolute.bottom-0.start-0 img {
    width: 100%;
  }
  .mobile_nav ul li a {
    padding: 0.8rem 1rem;
  }
  .footer-widget {
    padding-bottom: 45px;
    padding-top: 0;
  }
  .social-login ul li a {
    margin-left: auto;
    margin-right: auto;
  }
}
@media all and (min-width: 768px) and (max-width: 1023px) {
  .h1,
  h1 {
    font-size: 1.7rem;
  }
  .h2,
  h2 {
    font-size: 1.3rem;
  }
  .h3,
  h3 {
    font-size: 1.1rem;
  }
  .h4,
  h4 {
    font-size: 1rem;
  }
  .h5,
  h5 {
    font-size: 0.88rem;
  }
  .fs-1 {
    font-size: 1.7rem;
  }
  .fs-2 {
    font-size: 1.4rem;
  }
  .fs-3 {
    font-size: 1.1rem;
  }
}/*# sourceMappingURL=style.css.map */



.rp{border-radius: 50%;}
.banner_new_s{
  background: url('../Component/images/banner_new.jpg');
} 

.custm_cls_eng a {
  color: #fff !important;
}

em.btn.btn-primary.btn-md.fw-medium.full-width {
  font-style: normal;
  font-size: 14px;
  width: auto;
  float: left;
  padding: 10px;
  height: auto;
  background: snow;
  color: #000;
  border-color: #dadde1;
} 
.airLines-priceinfoy1.pt-4 a {
  color: #fff !important;
}

.accordion_body_n {padding-bottom: 0;}



.priceRangeSlider {
  width: 100%;
  margin: 0;
  text-align: center;
}

.horizontalSlider {
  width: 100%;
  height: 10px;

}

.thumb {
  height: 22px;
  width: 22px;
  background-color: #cd2c22;
  border-radius: 40%;
  cursor: pointer;
  top: -6px;
  margin: 0;
}

.track {
  background-color: #f3f6fa !important;
  height: 10px;
}

.track1 {
  background-color: #cd2c22 !important;
}

.heading {
  font-size: 14px;
  font-weight: 500;
}
.price-range-slider h3 {
  font-size: 14px;
  text-align: center;
  margin-bottom: 15px;
}

.border-zero_p {
  margin-top: 25px;
}
.track.track-1 {
  background: #cd2c22 !important;
}

.thumb {
  outline: none;
}

.bgs_black_z {
  background: #d5d5d5 !important;
}

button.btn.btn-primary.btn-md.fw-medium.full-width a {
  color: #fff;
}
.breadcrumb-item+.breadcrumb-item::before {
  display: none;
}




.fts input {
  background: none;
  height: 62px;
  outline: none;
  font-size: 14px;
  box-shadow: none;
  border: 1px solid #dadfe6;
  background-clip: initial;
  width: 100% !important;
  font-weight: 700 !important;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.form-group.hdd-arrow.mb-0.fts span {
  width: 100%;
}
.fts span{
  width: 100%;
}



.rs-picker-error>.rs-picker-input-group, .rs-picker-error>.rs-picker-input-group:hover {
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
}

.rs-picker-toggle-wrapper {
  width: 100% !important;
}


.p-autocomplete-panel.p-component.p-ripple-disabled.p-connected-overlay-enter-done {
  background: #ffffff;
  margin: 4px 0;
  border-radius: 0;
  padding: 10px;
  border-radius: 5px;
}


.p-autocomplete-items-wrapper ul {
  padding: 0;
  margin: 0;
}

.p-autocomplete-items-wrapper li {
  color: #fff;
  background: #cd2c22 !important;
  padding: 10px;
}

.p-autocomplete-items-wrapper {
  width: 100%;
  padding: 0;
}






.fts_imgs svg {
  position: absolute;
  right: 20px;
  top: 31px;
  color: #cd2c22;
  width: 35px;
  height: 20px;
}

.rs-picker-daterange .rs-input-group-addon .rs-btn-close {
  background: #cd2c22 !important;
  padding: 5px !important;
  border-radius: 50%;
  color: #fff;
  font-weight: bold;
  font-size: 15px;
}




.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
  border-color: #ffe6e5 !important;
  background: #ffe6e5;
  box-shadow: none !important;
  border-radius: 50%;
  color: #cd2c22;
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
    border-color: #cd2c22 !important;
  background: #cd2c22 !important;
  box-shadow: none !important;
  border-radius: 5px;
  color: #fff;
}

.rs-picker-daterange-panel {font-family: "Open Sans", sans-serif;}

.rs-calendar-table-cell-in-range:before {
  background: #ffe6e5 !important;
}


.rs-picker-daterange-content {
  font-family: "Rubik", sans-serif;
}

.rs-calendar-table-header-cell-content, .rs-calendar-table-header-cell-content:focus, .rs-calendar-table-header-cell-content:hover, .rs-calendar-table-header-cell-content:hover:focus {
  font-size: 15px;
}

.rs-calendar-header.rs-calendar-header-has-month {
  background: none;
  padding: 5px;
}

button.rs-calendar-header-title.rs-calendar-header-title-date.rs-btn.rs-btn-subtle.rs-btn-xs {
  font-size: 17px;
}

button.rs-calendar-header-forward.rs-btn-icon.rs-btn-icon-placement-left.rs-btn.rs-btn-subtle.rs-btn-xs {
  background: #cd2c22; color:#fff;
}

button.rs-calendar-header-backward.rs-btn-icon.rs-btn-icon-placement-left.rs-btn.rs-btn-subtle.rs-btn-xs {
  background: #cd2c22; color:#fff;
}

button.rs-calendar-header-backward.rs-btn-icon.rs-btn-icon-placement-left.rs-btn.rs-btn-subtle.rs-btn-xs svg {
  font-size: 20px;
}

button.rs-calendar-header-forward.rs-btn-icon.rs-btn-icon-placement-left.rs-btn.rs-btn-subtle.rs-btn-xs svg {
  font-size: 20px;
}

.rs-calendar {
}

.rs-calendar-body {
  padding: 0 !important;
}

.rs-picker-daterange-calendar-group {
  padding: 0 10px;
}

.rs-calendar-table-cell-content:hover {
  background: #cd2c22 !important; color:#fff;
}

button.rs-btn.rs-btn-primary.rs-btn-sm {
  background: #cd2c22;
  color:#fff;
}



.rs-picker-toolbar-ranges.rs-stack {
  display: none;
}

.rs-picker-daterange-header {
  background: #cd2c22;
  color: #fff;
  text-align: center;
}


.rs-picker-popup .rs-calendar .rs-calendar-table-cell:hover .rs-calendar-table-cell-content {
  color: #fff !important; background: #cd2c22 !important;
}




.active_engine {
  display: flex;
  width: 100%;
}

.active_engine span {
  margin-right: 15px !important;
  cursor: pointer;
}

.active_engine span label {
  cursor: pointer;
}

.active_engine span input {
  cursor: pointer;
}


.full_view_own_code {
  width: 100%;
  float: left;

}


.rs-input-group.rs-input-group-inside {
  border-color: #dadfe6 !important;
  box-shadow: none !important;
  outline: none !important;
}

.rs-picker-toggle-wrapper {
  box-shadow: none !important;
}

button.rs-picker-clean.rs-btn-close {
  background: #cd2c22 !important;
  border-radius: 50%;
  color: #fff;
  font-size: 15px;
  font-weight: 700;
  padding: 5px !important;
}


.Economy_flx_wrp {
  width: 100%;
  position: absolute;
  background: white;
  margin-top: 10px;
  border: 1px solid #dadfe6;
  border-radius: 6px;
}

.passngerDetails_head_n.fts {
  position: relative;
}

.Economy_flx_wrp ul {
  padding: 10px;
  margin: 0;
  float: left;
  width: 100%;
}

.Economy_flx_wrp ul li {
  padding: 5px 15px;
  font-size: 14px;
  line-height: 22px;
}

.Economy_flx_wrp ul li:hover {
  background: #dadfe6;
}



.pax_count_work {
  width: 100%;
  float: left;
  padding: 0 10px;
}

.pax_count_work span {
  font-family: 'Rubik';
  font-weight: 100;
  font-size: 14px;
}

.pax_count_wraper {
  display: flex;
  background: none;
  margin-bottom: 0;
  padding: 10px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e7e1e1;
}

.pax_count_multiple {
  display: flex;
  align-items: center;
}

.pax_count_multiple button {
  border: 1px solid #cd2c22;
  margin: 5px;
  border-radius: 50%;
  color: #cd2c22;
  font-size: 20px;
  background: none;
  line-height: 5px;
  padding: 0px;
  width: 30px;
  height: 30px;
  text-align: center;
  font-weight: 600;
  align-items: center;
}

.pax_count_multiple input {
  height: auto;
  width: 30px !important;
  padding-left: 0;
  padding-right: 0;
  text-align: center;
}


.font_customs {
    color: #000000 !important;
    font-size: 16px;
}

.fnt_ctme {
    font-size: 14px;
    color: #000000 !important;
   
}

h2.accordion-header button {
    padding-top: 0;
    padding-bottom: 0;
}

.accordion-item {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
}

.modal-dialog {
   
    margin-top: 0;
    margin-bottom: 0;
}


.modal-content {
    height: 100vh;
    overflow-x: hidden;
}
 